import React from 'react';
import './FlashcardResultsScreen.css'; // Import the CSS file for styling

const FlashcardResultsScreen = ({ data, handleNavigateHome }) => {

    return (
        <div className="flashcard-results-container">
            <h2 className="flashcard-results-title">Lesson Results</h2>
            <div className="flashcard-results-content">
                <div className="flashcard-results-section">
                    <h3 className="flashcard-results-section-title learned">Introduced</h3>
                    <ul className="flashcard-results-list">
                        {data.learned && data.learned.length > 0 ? (
                            data.learned.map((item, index) => (
                                <li key={index} className="flashcard-results-item">{item}</li>
                            ))
                        ) : (
                            <li className="flashcard-results-item"></li>
                        )}
                    </ul>
                </div>
                <div className="flashcard-results-section">
                    <h3 className="flashcard-results-section-title proficient">Proficient</h3>
                    <ul className="flashcard-results-list">
                        {data.proficient && data.proficient.length > 0 ? (
                            data.proficient.map((item, index) => (
                                <li key={index} className="flashcard-results-item">{item}</li>
                            ))
                        ) : (
                            <li className="flashcard-results-item"></li>
                        )}
                    </ul>
                </div>
                <div className="flashcard-results-section">
                    <h3 className="flashcard-results-section-title mastered">Mastered</h3>
                    <ul className="flashcard-results-list">
                        {data.mastered && data.mastered.length > 0 ? (
                            data.mastered.map((item, index) => (
                                <li key={index} className="flashcard-results-item">{item}</li>
                            ))
                        ) : (
                            <li className="flashcard-results-item"></li>
                        )}
                    </ul>
                </div>
            </div>
            <button onClick={handleNavigateHome} className="flashcard-results-button">
                Go to Home Screen
            </button>
        </div>
    );
};

export default FlashcardResultsScreen;