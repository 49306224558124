

import React, { useState, useEffect } from 'react';
import playIcon from './images/play100.png';
import playIconSlow from './images/turtle80.png';
import playIconFast from './images/falcon120.png';
import { getAudio, clearAudioCache } from './AudioUtils'; // Import the shared audio logic

const PlaySound = ({ flashcardId }) => {
  const [isPlaying, setIsPlaying] = useState(false); // State to track if audio is playing
  const [audioCache, setAudioCache] = useState({}); // Cache for audio sources

  // Clear the cache when the flashcardId changes (new flashcard)
  useEffect(() => {
    clearAudioCache(setAudioCache);
  }, [flashcardId]);

  // Function to play the audio from a given source
  const playAudio = (audioSrc) => {
    if (isPlaying) return; // Prevent playing if already playing
    
    const audio = new Audio(audioSrc); // Create new audio object
    setIsPlaying(true); // Set to playing state

    // Play the audio and handle ending
    audio.play().catch((error) => {
      console.error('Error playing audio file:', error);
      setIsPlaying(false); // Reset state if error occurs
    });

    // Event listener for when the audio finishes playing
    audio.addEventListener('ended', () => {
      setIsPlaying(false); // Reset to not playing when audio ends
    });

    // Optional: Reset playing state if the audio is paused or stopped prematurely
    audio.addEventListener('pause', () => {
      setIsPlaying(false);
    });
  };

  // Function to fetch and play the audio in sequence
  const getAndPlayAudio = async (flashcardId, speed) => {
    try {
      const audioSrc = await getAudio(flashcardId, speed, audioCache, setAudioCache); // Use shared getAudio
      playAudio(audioSrc); // Play the fetched audio
    } catch (error) {
      console.error('Error fetching or playing audio:', error);
    }
  };

  return (
    <div className="flex justify-center items-center space-x-4 mt-4 cursor-pointer">
      <img
        src={playIconSlow}
        alt="Play Slow"
        onClick={() => getAndPlayAudio(flashcardId, '80')}
        className={`w-16 h-16 cursor-pointer ${isPlaying ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={isPlaying} // Disable if audio is playing
      />
      <img
        src={playIcon}
        alt="Play Normal"
        onClick={() => getAndPlayAudio(flashcardId, '100')}
        className={`w-16 h-16 cursor-pointer ${isPlaying ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={isPlaying} // Disable if audio is playing
      />
      <img
        src={playIconFast}
        alt="Play Fast"
        onClick={() => getAndPlayAudio(flashcardId, '120')}
        className={`w-16 h-16 cursor-pointer ${isPlaying ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={isPlaying} // Disable if audio is playing
      />
    </div>
  );
};

export default PlaySound;