
import React, { useEffect, useState } from 'react';
import { useUser } from './UserContext';
import ApiService from './ApiService';
import { Slider, TextField, Box } from '@mui/material';
import './SetProficiencyLevel.css'; // Import the CSS file

const apiService = new ApiService();

function SetProficiencyLevel({ initialize }) {
    const { userId } = useUser();
    const [vocabulary, setVocabulary] = useState(null);
    const [numKnownVocabulary, setNumKnownVocabulary] = useState(0);
    const [message, setMessage] = useState('');
    const maxVocabularyLevel = vocabulary ? (vocabulary.length * 200) : 0;

    useEffect(() => {
        if (initialize) {
            apiService.fetchProficiencyPlacementVocabulary({ 'language': 'KR' })
                .then((data) => {
                    setVocabulary(data);
                    console.log(data);
                })
                .catch((error) => {
                    console.error('Error fetching proficiency placement vocabulary:', error);
                });
            apiService.getUserProficiencyPlacement({ 'user_id': userId, 'language': 'KR' })
            .then((data) => {
                setNumKnownVocabulary(data.num_known_vocab);
            })
            .catch((error) => {
                console.error('Error fetching user proficiency placement:', error);
            });
        }
    }, [initialize, userId]);

    const handleSliderChange = (event, newValue) => {
        setNumKnownVocabulary(newValue);
    };

    const handleInputChange = (event) => {
        const value = event.target.value === '' ? '' : Number(event.target.value);
        if (value >= 0 && value <= maxVocabularyLevel) {
            setNumKnownVocabulary(value);
        }
    };

    const handleSetProficiency = () => {
        const numKnown = parseInt(numKnownVocabulary, 10);
        if (numKnown <= maxVocabularyLevel) {
            apiService.setUserProficiencyPlacement({ 'user_id': userId, 'num_known_vocab': numKnown, 'language': 'KR' })
                .then(() => {
                    setMessage(`Your proficiency has been set to ${numKnownVocabulary}.`);
                })
                .catch((error) => {
                    console.error('Error setting proficiency level:', error);
                });
        } else {
            setMessage(`We currently only support a learning level up to ${maxVocabularyLevel}. Please pick a number <= ${maxVocabularyLevel}`);
        }
    };

    return (
        <div>
            <h3><u>Set the number of vocab you know and click the button</u></h3>
            <div className="proficiency-input">
                {message && <p>{message}</p>}
                
                <Box sx={{ width: 300, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Slider
                        value={typeof numKnownVocabulary === 'number' ? numKnownVocabulary : 0}
                        onChange={handleSliderChange}
                        step={1}
                        marks
                        min={0}
                        max={maxVocabularyLevel}
                        valueLabelDisplay="auto"
                        aria-labelledby="proficiency-slider"
                    />
                    <TextField
                        value={numKnownVocabulary}
                        onChange={handleInputChange}
                        inputProps={{
                            step: 1,
                            min: 0,
                            max: maxVocabularyLevel,
                            type: 'number',
                            'aria-labelledby': 'proficiency-slider',
                        }}
                        sx={{ width: 80 }}
                    />
                </Box>

                <button onClick={handleSetProficiency}>Set Known Vocabulary</button>
                <div>
                <span>
                    **You can adjust this later if you find the content too easy or too difficult.
                    <br />
                    **If you set a higher number then later reduce this number, any more difficult vocabulary/grammar that you prove to be proficient with will remain.
                    <br />
                    **We currently have content for 2000 vocabulary and 210 grammars
                    <br />
                    **Of course we plan to grow this over time, allowing the user to follow the standard frequency-based learning path, or pick which topics they're interested in so they can learn vocabulary they're most interested in.
                </span>
                </div>
            </div>
            <label><br></br></label>
            <label><br></br></label>
            <h3><u>Not sure what level to set?</u></h3>
            <label>We've listed some sample vocabulary at various difficult levels below.</label>
            <div className="vocabulary-container">
                <div className="vocabulary-box">
                    <div>
                        <u>Default</u>: This tool assumes the user knows 25 basic vocabulary and 8 grammars.
                    </div>
                </div>
                {vocabulary && vocabulary.map((group, index) => (
                    <div className="vocabulary-box" key={index}>
                        <div>
                            <u>Sample Vocabulary {index * 200 + 1}-{(index + 1) * 200}</u>: {group.join(', ')}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SetProficiencyLevel;