import React from 'react';

function Notifications() {
  return (
    <div style={{ padding: '10px', border: '3px solid black', marginRight: '20px' }}>
      <p style={{ margin: 0 }}>Set your proficiency level in User Settings! --></p>
    </div>
  );
}

export default Notifications;