import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ReadingFlashcard.css';
import { useUser } from './UserContext';

import TutorialCarousel from './TutorialCarousel';
import VocabLesson from './VocabLesson';
import GrammarLesson from './GrammarLesson'
import AskFlash from './AskFlash';

import FlashcardResultsScreen from './FlashcardResultsScreen'; // Import the renamed component

import PlaySound from './PlaySound';  // Import the new PlaySound component
import { getAudio, clearAudioCache } from './AudioUtils'; // Import audio logic


import ApiService from './ApiService'
const apiService = new ApiService();


function ReadingFlashcard() {
  const [flashcards, setFlashcards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [results, setResults] = useState([]);
  const [componentClicks, setComponentClicks] = useState([]);
  const [loaded, setLoaded] = useState(false); // Add a loaded state


  const [showExplanation, setShowExplanation] = useState(false);
  const [showTranslation, setShowTranslation] = useState(false);
  const [selectedExplanation, setSelectedExplanation] = useState('');
  const progressPercentage = ((currentIndex + 1) / flashcards.length) * 100;  // This calculates how much of the flashcards have been seen as a percentage.
  let navigate = useNavigate();
  const { userId } = useUser();

  const [postResult, setPostResult] = useState(null);
  const [showResultBox, setShowResultBox] = useState(false);
  const [calculatingResults, setCalculatingResults] = useState(false);


  const [selectedVocabWord, setSelectedVocabWord] = useState(null); // State to manage selected vocab word
  const [selectedGrammarLesson, setSelectedGrammarLesson] = useState(null); // New state for the selected lesson
  // const { title, sections } = selectedGrammarLesson?.lesson || {}; // Safe destructuring with fallback
  const [loadingLesson, setLoadingLesson] = useState(false); // State for lesson loading
  
  //Audio Cache Management
  const [isCacheWarmed, setIsCacheWarmed] = useState(false); // New state to track cache warm-up
  const [audioCache, setAudioCache] = useState({}); // Cache for audio sources


  const [showAskFlashContainer, setShowAskFlashContainer] = useState(false);
  const [conversation, setConversation] = useState([]);

  // Tutorial Related Values
  const [isTutorialVisible, setIsTutorialVisible] = useState(false);
  const handleFinish = () => {
    // setIsTutorialVisible(false);
    setIsTutorialVisible(false);
    apiService.setUserEventValue({
      'user_id':userId,
      'event_type': 'reading_flashcard_tutorial_completed',
      'event_value': {'completed': true}
    });
    // Handle any completion logic, like storing progress
  };

  

  useEffect(() => {
    if (flashcards.length > 0) {
      console.log(flashcards[currentIndex]?.target);
    }
  }, [currentIndex, flashcards]);

  
  useEffect(() => {

    const savedFlashcards = localStorage.getItem('reading_flashcards');
    const savedCurrentIndex = localStorage.getItem('reading_currentIndex');
    const savedResults = localStorage.getItem('reading_results');
    const savedComponentClicks = localStorage.getItem('reading_componentClicks');

    

    if (savedFlashcards) setFlashcards(JSON.parse(savedFlashcards));
    if (savedCurrentIndex) setCurrentIndex(JSON.parse(savedCurrentIndex));
    if (savedResults) setResults(JSON.parse(savedResults));
    if (savedComponentClicks) setComponentClicks(JSON.parse(savedComponentClicks));
    setLoaded(true); // Set loaded state to true after initializing
  }, []);

  useEffect(() => {
    if (!userId) return;
    apiService.getUserEventValue({'user_id': userId, 'event_type': 'reading_flashcard_tutorial_completed'})
      .then(data => {
        console.log('get user event data: ', data);
        if (data && data.completed === true){
          setIsTutorialVisible(false);
        }
        else {
          setIsTutorialVisible(true);
        }
      });
  }, [userId]);

  

  useEffect(() => {
    if (loaded) { // Only store to localStorage if loaded is true
      localStorage.setItem('reading_flashcards', JSON.stringify(flashcards));
      localStorage.setItem('reading_currentIndex', JSON.stringify(currentIndex));
      localStorage.setItem('reading_results', JSON.stringify(results));
      localStorage.setItem('reading_componentClicks', JSON.stringify(componentClicks));
    }
  }, [flashcards, currentIndex, results, componentClicks, loaded]);

  

  useEffect(() => {
    if (!userId || !loaded) return;


    const savedFlashcards = localStorage.getItem('reading_flashcards');
    const savedCurrentIndex = localStorage.getItem('reading_currentIndex');
    const savedResults = localStorage.getItem('reading_results');
    const savedComponentClicks = localStorage.getItem('reading_componentClicks');

    if (!savedFlashcards || !savedCurrentIndex || !savedResults || !savedComponentClicks ||
      JSON.parse(savedFlashcards).length === 0 ||
      JSON.parse(savedResults).length === 0 ||
      JSON.parse(savedComponentClicks).length === 0)  {
      console.log('No data in localStorage, calling API');

      //for testing
      // apiService.testGetRandomFlashcards({'language':'KR'})
      // .then(flashcards => {
      //     console.log('test flashcards: ', flashcards);
      //     setFlashcards(flashcards);
      //     setComponentClicks(flashcards.map(flashcard => Array(flashcard.components.length).fill(0)));
      //   });

      //for cloud
      apiService.fetchReadingFlashcardsForUser(userId)
        .then(flashcards => {
          setFlashcards(flashcards);
          setComponentClicks(flashcards.map(flashcard => Array(flashcard.components.length).fill(0)));
        
          // //Warm the cache for the first flashcard
          // if (flashcards.length > 0 && !isCacheWarmed) {
          //   console.log('gotta warm the cache')
          //   const firstFlashcard = flashcards[0];
          //   getAudio(firstFlashcard._id, '100', audioCache, setAudioCache)
          //     .then(() => setIsCacheWarmed(true)) // Mark cache as warmed
          //     .catch(err => console.error('Error warming the cache:', err));
          // }
        });
    } else {
      console.log('Data found in localStorage, no API call needed');
    }
  }, [userId, loaded]);

  useEffect(() => {
    if (flashcards.length > 0 && !isCacheWarmed) {
      console.log('Warming the cache');
      const firstFlashcard = flashcards[0];
      getAudio(firstFlashcard._id, '100', audioCache, setAudioCache)
        .then(() => setIsCacheWarmed(true))
        .catch(err => console.error('Error warming the cache:', err));
    }
  }, [flashcards, audioCache, isCacheWarmed]); // Depend only on flashcards

  //run on clicks to handle vocab and grammar clicks
  useEffect(() => {
    const handleClick = (event) => {
      const { type, key } = event.target.dataset;
      if (type === 'vocab') {
        handleVocabClick(key);
      } else if (type === 'grammar') {
        handleGrammarClick(key);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const askFlashClicked = () => {
    setShowAskFlashContainer(true)
  };

  const handleVocabClick = async (vocabKey) => {
    console.log('vocabKey: ', vocabKey)
    setLoadingLesson(true);
    // const lesson = await apiService.fetchVocabLesson({'vocab': vocabKey, 'source_language': "EN", target_language: "KR"});
    setSelectedGrammarLesson(null);
    setSelectedVocabWord(vocabKey);
    setLoadingLesson(false);
  };

  const handleGrammarClick = async (grammarId) => {
    setLoadingLesson(true);
    const lesson = await apiService.fetchGrammarLessonById({'grammar_id': grammarId, 'language': "KR"});
    setSelectedVocabWord(null);
    setSelectedGrammarLesson(lesson);
    setLoadingLesson(false);
  };

  const flashcard = flashcards[currentIndex];

  if (flashcards.length === 0) return <div>Retrieving Optimal Flashcards for the User...</div>;
  // if (currentIndex >= flashcards.length) return <div>All cards reviewed. <button onClick={() => navigate('/')}>Back to Home</button></div>;

  const fcAnswered = (result) => {
    setResults(prevResults => [
      ...prevResults, 
      { rating: result }
    ]);
  
    setSelectedVocabWord(null);
    setSelectedGrammarLesson(null);
    clearAudioCache(setAudioCache); // Clear audio cache for next flashcard
  
    const newResults = [...results, { rating: result }];
    setSelectedExplanation('');
    setShowTranslation(false);  // Reset translation visibility for the next card
    setCurrentIndex(currentIndex + 1);
  
    try {
      // Check if the conversation has any content
      if (conversation.length > 0) {
        // Await the post request to ensure it finishes before clearing the conversation
        apiService.postAskFlashConversation({
          user_id: userId,
          exercise_type: "reading_flashcard",
          exercise_data: flashcard,
          conversation: conversation,
      });
        // Clear the conversation after the API call is complete
        setConversation([]);
      }
    } catch (error) {
      console.error('Error posting AskFlash conversation:', error);
    }
  
    if (currentIndex + 1 < flashcards.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      lastFlashcardCompleted(newResults);
    }
  };

  const handleNavigateHome = () => {
    navigate('/platform');
  };

  //The lesson is completed, either by having all cards completed, or manually
  const lastFlashcardCompleted = (newResults) => {
    console.log('lastFlashcardCompleted')
    setCalculatingResults(true);
    apiService.postReadingFlashcardResults({
      user_id: userId,
      flashcards: flashcards,
      results: newResults,
      componentClicks: componentClicks
    })
    .then(data => {
      console.log(data);
      // Clear local storage
      localStorage.removeItem('reading_flashcards');
      localStorage.removeItem('reading_currentIndex');
      localStorage.removeItem('reading_results');
      localStorage.removeItem('reading_componentClicks');
      clearAudioCache(setAudioCache); // Clear audio cache for next flashcard
      //Set User Proficiency Results Update
      setPostResult(data);
      setShowResultBox(true);
      setCalculatingResults(false);
    })
    .catch(error => {
      console.error('Error posting results:', error);
    });
  };

  const lessonCompleted = () => {
    if (results.length > 0) {
      setCalculatingResults(true);
      apiService.postReadingFlashcardResults({
        user_id: userId,
        flashcards: flashcards,
        results: results,
        componentClicks: componentClicks
      })
      .then(data => {
        console.log(data);
        // Clear local storage
        localStorage.removeItem('reading_flashcards');
        localStorage.removeItem('reading_currentIndex');
        localStorage.removeItem('reading_results');
        localStorage.removeItem('reading_componentClicks');

        //Set User Proficiency Results Update
        setPostResult(data);
        setShowResultBox(true);
        setCalculatingResults(false);
        // navigate('/home_screen');
      })
      .catch(error => {
        console.error('Error posting results:', error);
      });
    }
    else {
      setPostResult({data: {'learned':[],'proficient':[],'mastered':[]}});
      setShowResultBox(true);
    }
  };

  const handleWordClick = (component, componentIndex) => {
    // setSelectedComponent(component);
    setShowExplanation(true);
    // setSelectedExplanation(`${component.component}: ${component.explanation}`);
    let explanation = `
      <div>
        <br> <strong>${component.component}</strong><br>
        <br> Meaning: ${component.M}</br>
        <br> Breakdown: ${component.breakdown}<br>
    `;

    if (component.V.length > 0) {
      explanation += '<br><u>Vocab</u><br>';
      component.V.forEach((vocab, index) => {
        const vocabKey = Object.keys(vocab)[0];
        explanation += `<span class="custom-underline-grammar-and-vocab" data-type="vocab" data-key="${vocabKey}">${vocabKey}</span><br>`;
      });
    }

    if (component.G.length > 0) {
      explanation += '<br><u>Grammar</u><br>';
      component.G.forEach((grammar, index) => {
        const grammarKey = Object.keys(grammar)[0];
        const grammarId = grammar[grammarKey];
        explanation += `<span class="custom-underline-grammar-and-vocab" data-type="grammar" data-key="${grammarId}">${grammarKey}</span><br>`;
      });
    }

    explanation += '</div>';

    setSelectedExplanation(explanation);


    // // update the tokens-clicks state
    // setComponentClicks(prevClicks => 
    //   prevClicks.map((clicks, idx) =>
    //     idx === currentIndex ? [...clicks.slice(0, componentIndex), 1, ...clicks.slice(componentIndex + 1)] : clicks
    //   )
    // );
    // update the componentClicks state
    setComponentClicks(prevClicks => 
      prevClicks.map((clicks, idx) => {
        if (idx === currentIndex) {
          const newClicks = [...clicks];
          newClicks[componentIndex] = newClicks[componentIndex] === 1 ? 0 : 1;
          return newClicks;
        }
        return clicks;
      })
    );
  };


  const handleShowAnswer = () => {
    setShowTranslation(true);  // Toggle the translation
  };

  const renderSentence = () => {
    if (!flashcard) {
      return "";
  }

    let renderedSentence = [];
    let sentenceCopy = flashcard.sentence;
  
    flashcard.components.forEach((component, index) => {
      let start = sentenceCopy.indexOf(component.component);
      if (start !== -1) {
        if (start > 0) {
          renderedSentence.push(<span key={`text-${index}`}>{sentenceCopy.substring(0, start)}</span>);
        }
        const isHighlighted = componentClicks[currentIndex][index] === 1;
        renderedSentence.push(
          <span
            key={`comp-${index}`}
            className={`custom-underline ${isHighlighted ? 'highlighted' : ''}`}
            onClick={() => handleWordClick(component, index)}
          >
            {component.component}
          </span>
        );
        sentenceCopy = sentenceCopy.substring(start + component.component.length);
      }
    });
  
    if (sentenceCopy) {
      renderedSentence.push(<span key="remaining">{sentenceCopy}</span>);
    }
  
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
        <div>
          {renderedSentence}
        </div>
      </div>
    );
  };
  
  const handleReview = async (flashcard, reviewStatus) => {
    console.log('reviewStatus: ', reviewStatus)
    try {
      await apiService.postFlashcardDeveloperReview({
        _id: flashcard._id,
        dev_review: reviewStatus
      });
    } catch (error) {
      console.error('Error posting the flashcard review:', error);
    }
  };

  return (
    <div>
      {calculatingResults ? (
        <div>Updating User Proficiency...</div>
      ) : showResultBox ? (
        <FlashcardResultsScreen
          data={postResult}
          handleNavigateHome={handleNavigateHome}
        />
      ) : (
        <div>
          <div className="bg-blue-800 p-5">
          <div className="flex flex-wrap gap-2">
            <button
              className="bg-white text-black py-2 px-4 rounded-lg"
              onClick={() => lessonCompleted()}
            >
              End Lesson
            </button>
            <button
              className="bg-white text-black py-2 px-4 rounded-lg"
              onClick={() => setIsTutorialVisible((prev) => !prev)}
            >
              Tutorial
            </button>
          </div>
  
            {isTutorialVisible && (
              <div className="bg-white p-4 my-4 rounded-lg border">
                <TutorialCarousel tutorialName="reading_flashcard" onFinish={handleFinish} />

                <p className="underline font-semibold">Tutorial</p>
                <p>Click on any components/vocab/grammar you'd like more information for.</p>
                <p>Click (mark in red) any components that you would like to see again.</p>
                <p>This exercise only affects your reading proficiency.</p>
              </div>
            )}
  
            {/* Progress Bar */}
            <div className="relative w-full h-6 bg-gray-300 my-5 rounded-lg">
              <div
                className="bg-green-500 h-full rounded-lg"
                style={{ width: `${progressPercentage}%` }}
              ></div>
              <div className="absolute inset-0 flex items-center justify-center font-bold">
                {currentIndex}/{flashcards.length}
              </div>
            </div>
  
            {/* Flashcard Content */}
            <div className="bg-gray-100 p-5 rounded-lg shadow-lg border mb-5">
            <div className="text-center mb-5">{renderSentence()}</div>
  
              <div className="flex justify-center items-center space-x-4 mt-4 cursor-pointer">
                <PlaySound flashcardId={flashcard._id} />
              </div>
  
              {/* Translation Button */}
              <div className="text-center mt-4">
                {!showTranslation ? (
                  <div className="inline-block">
                    <button
                      className="bg-blue-800 text-white w-50 h-10 rounded-md flex items-center justify-center"
                      onClick={handleShowAnswer}
                    >
                      Show Translation
                    </button>
                  </div>
                ) : (
                  <div>
                    <p className="mt-4">{flashcard.translation}</p>
                  </div>
                )}
              </div>
  
              {/* Next Button */}
              <div className="text-center mt-4">
              <div className="inline-block">
                <button
                  className="bg-blue-800 text-white w-40 h-10 rounded-md flex items-center justify-center"
                  onClick={() => fcAnswered('difficult')}
                >
                  Next Sentence
                </button>
              </div>
              </div>

              {/* Usage Notes */}
              {showTranslation && flashcard.usage_notes && flashcard.usage_notes.EN && (
                <p className="text-left mt-2">
                  <u>Usage Notes:</u> {flashcard.usage_notes.EN}
                </p>
              )}
  
              <div className="flex justify-center items-center mt-4">
              <button
                type="button"
                className="hover:bg-transparent bg-transparent text-blue-500 underline focus:outline-none text-center"
                onClick={askFlashClicked}
              >
                Confused? <br /> Ask Flash
              </button>
            </div>
            </div>
  
            {/* Ask Flash Component */}
            {showAskFlashContainer && (
              <div className="bg-white p-5 my-4 rounded-lg border">
                <AskFlash
                  userId={userId}
                  exerciseType="reading_flashcard"
                  exerciseData={flashcard}
                  conversation={conversation}
                  setConversation={setConversation}
                />
              </div>
            )}
  
            {/* Explanation Section */}
            <div className="bg-white p-5 my-4 rounded-lg border">
              {showExplanation && selectedExplanation ? (
                <div>
                  <p dangerouslySetInnerHTML={{ __html: selectedExplanation }} />
                </div>
              ) : (
                <p className="text-center">Select an underlined component of the sentence to see more information</p>
              )}
            </div>
  
            {/* Developer Mode */}
            {userId === '355e60e6e66fc93e32ff1ae5c0f5fc73b4d010529c939721af8b29661eac0849' && (
              <div className="my-4">
                <h3>Developer Mode</h3>
                {flashcard.dev_review ? (
                  <span>{flashcard.dev_review}</span>
                ) : (
                  <div>
                    <button
                      className="bg-green-500 text-white py-2 px-4 rounded-md mr-2"
                      onClick={() => handleReview(flashcard, 'Pass')}
                    >
                      Mark as Good
                    </button>
                    <button
                      className="bg-red-500 text-white py-2 px-4 rounded-md"
                      onClick={() => handleReview(flashcard, 'Fail')}
                    >
                      Mark as Bad
                    </button>
                  </div>
                )}
              </div>
            )}
  
            {/* Grammar/Vocab Sidebar */}
            <div className="bg-white p-5 rounded-lg border">
              {loadingLesson ? (
                <p>Loading...</p>
              ) : selectedGrammarLesson ? (
                <GrammarLesson lessonId={selectedGrammarLesson._id} />
              ) : selectedVocabWord ? (
                <VocabLesson vocabWord={selectedVocabWord} />
              ) : showExplanation && selectedExplanation ? (
                <p>Click on blue highlighted vocab/grammar to see more information</p>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default ReadingFlashcard;