import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import VocabLesson from './VocabLesson';
import GrammarLesson from './GrammarLesson'
import TutorialCarousel from './TutorialCarousel';


import './ListeningFlashcard.css';
import PlaySound from './PlaySound';  // Import the new PlaySound component
import { getAudio, clearAudioCache } from './AudioUtils'; // Import audio logic

import FlashcardResultsScreen from './FlashcardResultsScreen'; // Import the renamed component
import AskFlash from './AskFlash';


import ApiService from './ApiService'
const apiService = new ApiService();

function ListeningFlashcard() {
    const [flashcards, setFlashcards] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [results, setResults] = useState([]);
    const [componentClicks, setComponentClicks] = useState([]); // for tracking what user specifically doesn't know
    const [loaded, setLoaded] = useState(false); // Add a loaded state
    const [postResult, setPostResult] = useState(null);
    const [showResultBox, setShowResultBox] = useState(false);
    const [calculatingResults, setCalculatingResults] = useState(false);

    const [showSentence, setShowSentence] = useState(false);
    const [showTranslation, setShowTranslation] = useState(false);
    const [selectedExplanation, setSelectedExplanation] = useState('');
    const [isTutorialVisible, setIsTutorialVisible] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const progressPercentage = ((currentIndex + 1) / flashcards.length) * 100;  // This calculates how much of the flashcards have been seen as a percentage.

    // const [userId, setUserId] = useState('');

    let navigate = useNavigate();
    const { userId } = useUser();

    const [selectedVocabWord, setSelectedVocabWord] = useState(null); // State to manage selected vocab word
    const [selectedGrammarLesson, setSelectedGrammarLesson] = useState(null); // New state for the selected lesson
    const [loadingLesson, setLoadingLesson] = useState(false); // State for lesson loading
    //Audio Cache Management
    const [isCacheWarmed, setIsCacheWarmed] = useState(false); // New state to track cache warm-up
    const [audioCache, setAudioCache] = useState({}); // Cache for audio sources
    const [showAskFlashContainer, setShowAskFlashContainer] = useState(false);
    const [conversation, setConversation] = useState([]);

    const askFlashClicked = () => {
      setShowAskFlashContainer(true)
    };
  

    const handleVocabClick = async (vocabKey) => {
        console.log('vocabKey: ', vocabKey)
        setLoadingLesson(true);
        // const lesson = await apiService.fetchVocabLesson({'vocab': vocabKey, 'source_language': "EN", target_language: "KR"});
        setSelectedGrammarLesson(null);
        setSelectedVocabWord(vocabKey);
        setLoadingLesson(false);
      };
    
      const handleGrammarClick = async (grammarId) => {
        setLoadingLesson(true);
        const lesson = await apiService.fetchGrammarLessonById({'grammar_id': grammarId, 'language': "KR"});
        setSelectedVocabWord(null);
        setSelectedGrammarLesson(lesson);
        setLoadingLesson(false);
      };

      useEffect(() => {
        if (flashcards.length > 0) {
          console.log(flashcards[currentIndex]?.target);
        }
      }, [currentIndex, flashcards]);

      useEffect(() => {
        apiService.getUserEventValue({'user_id': userId, 'event_type': 'listening_flashcard_tutorial_completed'})
          .then(data => {
            console.log('get user event data: ', data);
            if (data && data.completed === true){
              setIsTutorialVisible(false);
            }
            else {
              setIsTutorialVisible(true);
            }
          });
      }, [userId]);

      useEffect(() => {
        console.log('useEffect 1: Initializing state from localStorage');
    
        const savedFlashcards = localStorage.getItem('listening_flashcards');
        const savedCurrentIndex = localStorage.getItem('listening_currentIndex');
        const savedResults = localStorage.getItem('listening_results');
        const savedComponentClicks = localStorage.getItem('listening_componentClicks');
    
        console.log('Loaded from localStorage:', {
          savedFlashcards,
          savedCurrentIndex,
          savedResults,
          savedComponentClicks,
        });
    
        if (savedFlashcards) setFlashcards(JSON.parse(savedFlashcards));
        if (savedCurrentIndex) setCurrentIndex(JSON.parse(savedCurrentIndex));
        if (savedResults) setResults(JSON.parse(savedResults));
        if (savedComponentClicks) setComponentClicks(JSON.parse(savedComponentClicks));
        setLoaded(true); // Set loaded state to true after initializing
      }, []);
    
      useEffect(() => {
        if (loaded) { // Only store to localStorage if loaded is true
          localStorage.setItem('listening_flashcards', JSON.stringify(flashcards));
          localStorage.setItem('listening_currentIndex', JSON.stringify(currentIndex));
          localStorage.setItem('listening_results', JSON.stringify(results));
          localStorage.setItem('listening_componentClicks', JSON.stringify(componentClicks));
        }
      }, [flashcards, currentIndex, results, componentClicks, loaded]);
    
      useEffect(() => {
        if (!userId || !loaded) return;
    
        console.log('useEffect 3: Checking localStorage before API call');
    
        const savedFlashcards = localStorage.getItem('listening_flashcards');
        const savedCurrentIndex = localStorage.getItem('listening_currentIndex');
        const savedResults = localStorage.getItem('listening_results');
        const savedComponentClicks = localStorage.getItem('listening_componentClicks');
    
        if (!savedFlashcards || !savedCurrentIndex || !savedResults || !savedComponentClicks ||
          JSON.parse(savedFlashcards).length === 0 ||
          JSON.parse(savedResults).length === 0 ||
          JSON.parse(savedComponentClicks).length === 0)  {
          console.log('No data in localStorage, calling API');
          apiService.fetchListeningFlashcardsForUser(userId)
            .then(flashcards => {
              setFlashcards(flashcards);
              setComponentClicks(flashcards.map(flashcard => Array(flashcard.components.length).fill(0)));
            });
        } else {
          console.log('Data found in localStorage, no API call needed');
        }
      }, [userId, loaded]);

      useEffect(() => {
        if (flashcards.length > 0 && !isCacheWarmed) {
          console.log('Warming the cache');
          const firstFlashcard = flashcards[0];
          getAudio(firstFlashcard._id, '100', audioCache, setAudioCache)
            .then(() => setIsCacheWarmed(true))
            .catch(err => console.error('Error warming the cache:', err));
        }
      }, [flashcards, audioCache, isCacheWarmed]); // Depend only on flashcards

    useEffect(() => {
    const handleClick = (event) => {
        const { type, key } = event.target.dataset;
        if (type === 'vocab') {
        handleVocabClick(key);
        } else if (type === 'grammar') {
        handleGrammarClick(key);
        }
    };

    document.addEventListener('click', handleClick);

    return () => {
        document.removeEventListener('click', handleClick);
    };
    }, []);

      const renderSentence = () => {
        if (!flashcards.length || currentIndex >= flashcards.length) return null;
        const flashcard = flashcards[currentIndex];
        let renderedSentence = [];
        let sentenceCopy = flashcard.sentence;

        flashcard.components.forEach((component, index) => {
            let start = sentenceCopy.indexOf(component.component);
            if (start !== -1) {
                if (start > 0) {
                    renderedSentence.push(<span key={`text-${index}`}>{sentenceCopy.substring(0, start)}</span>);
                }
                const isHighlighted = componentClicks[currentIndex][index] === 1;
                renderedSentence.push(
                    <span
                        key={`comp-${index}`}
                        className={`custom-underline ${isHighlighted ? 'highlighted' : ''}`}
                        onClick={() => handleWordClick(component, index)}
                    >
                        {component.component}
                    </span>
                );
                sentenceCopy = sentenceCopy.substring(start + component.component.length);
            }
        });

        // Add any remaining text
        if (sentenceCopy.length > 0) {
            renderedSentence.push(<span key="remaining-text">{sentenceCopy}</span>);
        }

        return renderedSentence;
    }


    const handleShowSentence = () => {
        setShowSentence(true);
    };

    const handleShowTranslation = () => {
        setShowTranslation(true);
    };

    const handleFinish = () => {
      // setIsTutorialVisible(false);
      setIsTutorialVisible(false);
      console.log('user id in handlefinish: ', userId);
      apiService.setUserEventValue({
        'user_id':userId,
        'event_type': 'listening_flashcard_tutorial_completed',
        'event_value': {'completed': true}
      });
      // Handle any completion logic, like storing progress
    };

    const fcAnswered = (result) => {
        setResults(prevResults => [
        ...prevResults, 
        { rating: result }
        ]);
        const newResults = [...results, { rating: result }]; 
        setShowSentence(false);
        setShowTranslation(false);
        setSelectedExplanation('');
        setSelectedGrammarLesson(null);
        setSelectedVocabWord(null);
        clearAudioCache(setAudioCache); // Clear audio cache for next flashcard

        if (conversation.length > 0) {
          // Await the post request to ensure it finishes before clearing the conversation
          apiService.postAskFlashConversation({
            user_id: userId,
            exercise_type: "listening_flashcard",
            exercise_data: currentFlashcard,
            conversation: conversation,
        });
          setConversation([]);
        }

        if (currentIndex + 1 < flashcards.length) {
        setCurrentIndex(currentIndex + 1);
        } else {
            lastFlashcardCompleted(newResults)
        }
    };

    const handleNavigateHome = () => {
      navigate('/platform');
    };

    //The lesson is completed, either by having all cards completed, or manually
    const lastFlashcardCompleted = (newResults) => {
        console.log('lesson completed');
        setCalculatingResults(true);
        apiService.postListeningFlashcardResults({
            user_id: userId,
            flashcards: flashcards,
            results: newResults,
            componentClicks: componentClicks
           })
        .then(data => {
        localStorage.removeItem('listening_flashcards');
        localStorage.removeItem('listening_currentIndex');
        localStorage.removeItem('listening_results');
        localStorage.removeItem('listening_componentClicks');
        clearAudioCache(setAudioCache); // Clear audio cache for next flashcard
        setPostResult(data);
        setShowResultBox(true);
        setCalculatingResults(false);
        // navigate('/home_screen');
        })
      .catch(error => {
        console.error('Error posting results:', error);
      });
    };

    //The lesson is completed, either by having all cards completed, or manually
    const lessonCompleted = () => {
        console.log('lesson completed');
        setCalculatingResults(true);
        apiService.postListeningFlashcardResults({
            user_id: userId,
            flashcards: flashcards,
            results: results,
            componentClicks: componentClicks
           })
           .then(data => {
            console.log(data);
            localStorage.removeItem('listening_flashcards');
            localStorage.removeItem('listening_currentIndex');
            localStorage.removeItem('listening_results');
            localStorage.removeItem('listening_componentClicks');
            setPostResult(data);
            setShowResultBox(true);
            setCalculatingResults(false);
            // navigate('/home_screen');
           })
          .catch(error => {
            console.error('Error posting results:', error);
          });
    };

    const handleWordClick = (component, componentIndex) => {
        // setSelectedComponent(component);
        setShowExplanation(true);
        // setSelectedExplanation(`${component.component}: ${component.explanation}`);
        let explanation = `
          <div>
            <u>Component</u><br> ${component.component}<br>
            <u>Meaning</u><br> ${component.M}<br>
            <u>Breakdown</u><br> ${component.breakdown}<br>
        `;
    
        if (component.V.length > 0) {
          explanation += '<br><u>Vocab</u><br>';
          component.V.forEach((vocab, index) => {
            const vocabKey = Object.keys(vocab)[0];
            explanation += `<span class="custom-underline-grammar-and-vocab" data-type="vocab" data-key="${vocabKey}">${vocabKey}</span><br>`;
          });
        }
    
        if (component.G.length > 0) {
          explanation += '<br><u>Grammar</u><br>';
          component.G.forEach((grammar, index) => {
            const grammarKey = Object.keys(grammar)[0];
            const grammarId = grammar[grammarKey];
            explanation += `<span class="custom-underline-grammar-and-vocab" data-type="grammar" data-key="${grammarId}">${grammarKey}</span><br>`;
          });
        }
    
        explanation += '</div>';
    
        setSelectedExplanation(explanation);
    
    
        setComponentClicks(prevClicks => 
          prevClicks.map((clicks, idx) => {
            if (idx === currentIndex) {
              const newClicks = [...clicks];
              newClicks[componentIndex] = newClicks[componentIndex] === 1 ? 0 : 1;
              return newClicks;
            }
            return clicks;
          })
        );
      };

    if (flashcards.length === 0) {
        return <div>Retrieving Optimal Flashcards for the User...</div>;
    }

    const currentFlashcard = flashcards[currentIndex];

    return (
      <div>
        {calculatingResults ? (
          <div>Updating User Proficiency...</div>
        ) : showResultBox ? (
          <FlashcardResultsScreen
            data={postResult}
            handleNavigateHome={handleNavigateHome}
          />
        ) : (
          <div className="flashcard-background-container">
            <div className="body-container">
              <div className="flex flex-wrap gap-2">
                <button
                  className="custom-button"
                  onClick={() => lessonCompleted()}
                  style={{ marginRight: "10px" }}
                >
                  End Lesson
                </button>
                <button
                  className="custom-button"
                  onClick={() => setIsTutorialVisible((prev) => !prev)}
                >
                  Tutorial
                </button>
              </div>
    
              {isTutorialVisible && (
                <div className="flashcard-container">
                  <TutorialCarousel tutorialName="listening_flashcard" onFinish={handleFinish} />
                  <p className="underline font-semibold">Tutorial</p>
                  <p>Click on any components/vocab/grammar you'd like more information for.</p>
                  <p>Click (mark in red) any components that you would like to see again.</p>
                  <p>This exercise only affects your listening proficiency.</p>
                </div>
              )}
    
              {/* Progress Bar */}
              <div className="relative w-full h-6 bg-gray-300 my-5 rounded-lg">
                <div
                  className="bg-green-500 h-full rounded-lg"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
                <div className="absolute inset-0 flex items-center justify-center font-bold">
                  {currentIndex}/{flashcards.length}
                </div>
              </div>
    
              {/* Flashcard Content */}
              <div className="flashcard-container bg-gray-100 p-5 rounded-lg shadow-lg border mb-5">
                <div className="flex justify-center items-center space-x-4 mt-4 cursor-pointer">
                  <PlaySound flashcardId={currentFlashcard._id} />
                </div>
    
                {/* Sentence Display */}
                <div className="flex justify-center items-center mt-5">
                  {!showSentence && (
                    <button
                      className="bg-blue-800 text-white w-40 h-10 rounded-md flex items-center justify-center"
                      onClick={handleShowSentence}
                    >
                      Show Sentence
                    </button>
                  )}
                </div>
    
                {showSentence && (
                <div>
                  {/* Render Sentence */}
                  <div className="text-center mb-5">{renderSentence()}</div>

                  {/* Translation Button */}
                  <div className="text-center mt-5">
                    {!showTranslation ? (
                      <div className="inline-block">
                        <button
                          className="bg-blue-800 text-white w-50 h-10 rounded-md flex items-center justify-center"
                          onClick={handleShowTranslation}
                        >
                          Show Translation
                        </button>
                      </div>
                    ) : (
                      <div>
                        <p className="mt-4">{currentFlashcard.translation}</p>
                        {currentFlashcard.usage_notes?.EN && (
                          <p className="text-left mt-2">
                            <u>Usage Notes:</u> {currentFlashcard.usage_notes.EN}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

    
                {/* Next Sentence Button */}
                <div className="text-center mt-4">
                  <div className="inline-block">
                    <button
                      className="bg-blue-800 text-white w-40 h-10 rounded-md flex items-center justify-center"
                      onClick={() => fcAnswered("difficult")}
                    >
                      Next Sentence
                    </button>
                  </div>
                </div>
    
                <div className="flex justify-center items-center mt-4">
                  <button
                    type="button"
                    className="hover:bg-transparent bg-transparent text-blue-500 underline focus:outline-none text-center"
                    onClick={askFlashClicked}
                  >
                    Confused? <br /> Ask Flash
                  </button>
                </div>
              </div>
    
              {/* Ask Flash Component */}
              {showAskFlashContainer && (
                <div className="bg-white p-5 my-4 rounded-lg border">
                  <AskFlash
                    userId={userId}
                    exerciseType="listening_flashcard"
                    exerciseData={currentFlashcard}
                    conversation={conversation}
                    setConversation={setConversation}
                  />
                </div>
              )}
    
              {/* Explanation Section */}
              <div className="bg-white p-5 my-4 rounded-lg border">
                {showExplanation && selectedExplanation ? (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: selectedExplanation }} />
                  </div>
                ) : showSentence ? (
                  <p className="text-center">
                    Select an underlined component of the sentence to see more
                    information
                  </p>
                ) : (
                  <p className="text-center"></p>
                )}
              </div>
    
              {/* Grammar/Vocab Sidebar */}
              <div className="bg-white p-5 rounded-lg border">
                {loadingLesson ? (
                  <p>Retrieving Optimal Flashcards for the User</p>
                ) : selectedGrammarLesson ? (
                  <GrammarLesson lessonId={selectedGrammarLesson._id} />
                ) : selectedVocabWord ? (
                  <VocabLesson vocabWord={selectedVocabWord} />
                ) : showExplanation && selectedExplanation ? (
                  <p>Click on blue highlighted vocab/grammars to see more information</p>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
}

export default ListeningFlashcard;