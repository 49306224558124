

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Platform from './Platform';
import ReadingFlashcard from './ReadingFlashcard';
import ListeningFlashcard from './ListeningFlashcard';
import LoginScreen from './LoginScreen';
import Grammar from './Grammar';
import Vocabulary from './Vocabulary';
import GrammarLesson from './GrammarLesson';
import VocabLesson from './VocabLesson';
import Statistics from './Statistics';
import UserSettings from './UserSettings';
import HomePage from './HomePage';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import ClozeExercise from './ClozeExercise';

// Import other components

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/reading_practice" element={<ReadingFlashcard />} />
        <Route path="/listening_practice" element={<ListeningFlashcard />} />
        <Route path="/grammar_lessons" element={<Grammar />} />
        <Route path="/grammar_lessons/:lessonId" element={<GrammarLesson />} />
        <Route path="/vocabulary" element={<Vocabulary />} />
        <Route path="/vocabulary/:vocabWord" element={<VocabLesson />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/settings" element={<UserSettings />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_of_service" element={<TermsOfService />} />
        <Route path="/cloze" element={<ClozeExercise />} />



        {/* Define other routes */}
      </Routes>
    </Router>
  );
}

export default App;
