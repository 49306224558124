

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ApiService from './ApiService';
import './GrammarLesson.css'; // Import the CSS file for additional styling if needed

const apiService = new ApiService();

function GrammarLesson({ lessonId: propLessonId }) {
  const { lessonId: paramLessonId } = useParams();
  const lessonId = propLessonId || paramLessonId;
  const [lesson, setLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [language, grammarId] = lessonId.split('-').slice(-2);
  const [openSections, setOpenSections] = useState({});

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const data = await apiService.fetchGrammarLessonById({ grammar_id: grammarId, language: language });
        console.log(data);
        console.log(data.grammar_comparison_lessons);
        setLesson(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the lesson:', error);
        setLoading(false);
      }
    };
    fetchLesson();
  }, [grammarId, language, lessonId]);

  const toggleSection = (index) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [index]: !prevOpenSections[index],
    }));
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!lesson) {
    return <p>Lesson not found</p>;
  }

  const { title, sections } = lesson.lesson;
  const { grammar_comparison_lessons } = lesson; // Access directly from lesson

  return (
    <div className="lesson-container p-4">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      {sections.map((section, index) => (
        section.title !== 'Comparisons' && (
          <div key={index} className="section mb-6">
            <h3 className="text-xl font-semibold mb-2">{section.title}</h3>
            <p className="text-gray-700">{section.content}</p>
          </div>
        )
      ))}

      {grammar_comparison_lessons && grammar_comparison_lessons.length > 0 && (
        <div className="grammar-comparisons">
          <h3 className="text-xl font-semibold mb-4">Grammar Comparisons</h3>
          {grammar_comparison_lessons.map((comparison, index) => (
            <div key={index} className="mb-4">
              <button
                className="w-full text-left font-semibold text-gray-800 hover:text-gray-600 focus:outline-none flex items-center"
                onClick={() => toggleSection(index)}
              >
                <span className="mr-2">{openSections[index] ? '-' : '+'}</span>
                {comparison.grammar1.grammar} vs {comparison.grammar2.grammar}
              </button>
              {openSections[index] && (
                <div className="mt-2 pl-4 border-l-2 border-gray-300">
                  <div className="comparison-section mb-4">
                    <h4 className="font-semibold">Similarities</h4>
                    <ul className="list-disc list-inside ml-4">
                      {comparison.similarities.map((similarity, idx) => (
                        <li key={idx} className="text-gray-700">{similarity}</li>
                      ))}
                    </ul>

                    <h4 className="font-semibold mt-4">Differences</h4>
                    <ul className="list-disc list-inside ml-4">
                      {comparison.differences.map((difference, idx) => (
                        <li key={idx} className="text-gray-700">{difference}</li>
                      ))}
                    </ul>

                    <h4 className="font-semibold mt-4">Representative Examples</h4>
                    <ul className="list-disc list-inside ml-4">
                      <li className="text-gray-700">
                        <strong>{comparison.grammar1.grammar}:</strong> {comparison.representative_examples[comparison.grammar1.grammar]}
                      </li>
                      <li className="text-gray-700">
                        <strong>{comparison.grammar2.grammar}:</strong> {comparison.representative_examples[comparison.grammar2.grammar]}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default GrammarLesson;
