// src/components/TermsOfService.js
import React from 'react';

const TermsOfService = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4 text-center">Terms of Service</h1>
            <p className="text-center mb-8">Last Updated: 7/31/2024</p>
            <section className="bg-white p-8 rounded-lg shadow-lg border-2 border-gray-300">
                <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
                <p className="mb-4">
                    By accessing and using FlashFluency (the "App"), you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by these terms, please do not use this App.
                </p>

                <h2 className="text-2xl font-semibold mb-4">2. Description of Service</h2>
                <p className="mb-4">
                    FlashFluency provides users with access to a collection of resources, including sample sentences, vocabulary data, and various other features (the "Service"). Any new features or tools which are added to the current Service shall also be subject to these Terms of Service.
                </p>

                <h2 className="text-2xl font-semibold mb-4">3. User Responsibilities</h2>
                <p className="mb-4">
                    Users are responsible for maintaining the confidentiality of their account information, including their social media login credentials. Users agree to use the Service only for lawful purposes and in accordance with these Terms of Service.
                </p>

                <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property and Ownership</h2>
                <p className="mb-4">
                    All content provided on FlashFluency, including but not limited to text, graphics, logos, images, and software, is the property of FlashFluency. Users are not permitted to copy, distribute, modify, or create derivative works from any content found on this App without express written permission from FlashFluency.
                </p>
                <p className="mb-4">
                    Unauthorized use of any content on this App may violate copyright, trademark, and other laws. FlashFluency reserves all rights not expressly granted in these Terms of Service.
                </p>

                <h2 className="text-2xl font-semibold mb-4">5. Termination</h2>
                <p className="mb-4">
                    We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
                </p>

                <h2 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h2>
                <p className="mb-4">
                    In no event shall FlashFluency, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.
                </p>

                <h2 className="text-2xl font-semibold mb-4">7. Changes to the Terms</h2>
                <p className="mb-4">
                    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                </p>
            </section>
        </div>
    );
};

export default TermsOfService;