
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
const UserContext = createContext(undefined);

// Provider component that wraps your app and makes the user data accessible everywhere
export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(() => {
    // Check localStorage for an existing userId
    const storedUserId = localStorage.getItem('userId');
    return storedUserId ? JSON.parse(storedUserId) : null;
  });

  const [language, setLanguage] = useState(() => {
    // Check localStorage for an existing language, default to "KR" if not found
    const storedLanguage = localStorage.getItem('language');
    return storedLanguage ? JSON.parse(storedLanguage) : "KR";
  });

  useEffect(() => {
    if (userId) {
      // Save userId to localStorage when it changes
      localStorage.setItem('userId', JSON.stringify(userId));
    } else {
      // Remove userId from localStorage if it's null
      localStorage.removeItem('userId');
    }
  }, [userId]);

  useEffect(() => {
    if (language) {
      // Save language to localStorage when it changes
      localStorage.setItem('language', JSON.stringify(language));
    } else {
      // Remove language from localStorage if it's null
      localStorage.removeItem('language');
    }
  }, [language]);

  return (
    <UserContext.Provider value={{ userId, setUserId, language, setLanguage }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};