// src/HomePage.js
import React from 'react';
import WhyFlashFluency from './WhyFlashFluency';
import UserFeedback from './UserFeedback';
import DiscordLink from './DiscordLink'; // Import the DiscordLink component


const HomePage = () => {
    return (
        <div className='tailwind-scope'>
        <div className="flex flex-col min-h-screen bg-gray-100">
            <header className="bg-blue-600 text-white py-4">
                <h1 className="text-center text-3xl font-bold">Flash Fluency</h1>
            </header>
            <main className="flex-grow container mx-auto p-4">
                <div className="text-center my-8">
                    <div className="bg-blue-200 text-black-900 p-4 rounded-lg mb-4">
                        <h2 className="text-2xl font-semibold">Welcome to the Flash Fluency Alpha!</h2>
                        <p className="mt-2">We're an English-to-Korean language learning app.</p> 
                        <p className="mt-2">Please feel free to check out our app!</p>
                        <p className="mt-2">During this initial alpha phase your feedback is uber appreciated.  Feel free to provide feedback below or via our discord.</p>
                        <DiscordLink />
                    </div>
                    <button 
                        onClick={() => window.location.href = '/login'} 
                        className="px-6 py-3 bg-green-500 text-black font-bold rounded-full hover:bg-green-600 transition duration-300"
                    >
                        Try Out the App!
                    </button>
                </div>
                <WhyFlashFluency />
                <UserFeedback />
            </main>
            <footer className="bg-gray-800 text-white py-4">
                <nav className="flex justify-center space-x-4">
                    <a href="/terms_of_service" className="hover:underline">Terms of Service</a>
                    <a href="/privacy_policy" className="hover:underline">Privacy Policy</a>
                </nav>
            </footer>
        </div>
        </div>
    );
};

export default HomePage;