
// export default ClozeExercise;
import React, { useState, useEffect, useRef, useCallback } from 'react';
import ApiService from './ApiService';
import { useUser } from './UserContext';
import FlashcardResultsScreen from './FlashcardResultsScreen'; // Import the renamed component
import AskFlash from './AskFlash';
import TutorialCarousel from './TutorialCarousel';
import PlaySound from './PlaySound';  // Import the new PlaySound component
import { getAudio, clearAudioCache } from './AudioUtils'; // Import audio logic



import './ReadingFlashcard.css'; // Ensure you have the same styling
import { useNavigate } from 'react-router-dom';

const apiService = new ApiService();

function ClozeExercise() {
  const { userId } = useUser();
  const [clozes, setClozes] = useState([]);
  const [currentClozeIndex, setCurrentClozeIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [isCorrectSoFar, setIsCorrectSoFar] = useState(false);
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const [results, setResults] = useState([]);
  const [spaceError, setSpaceError] = useState(false); // New state for space error
  const [postResult, setPostResult] = useState(null);
  const [isTutorialVisible, setIsTutorialVisible] = useState(false);
  const [showResultBox, setShowResultBox] = useState(false);
  const [calculatingResults, setCalculatingResults] = useState(false);
  const [triggerPostResults, setTriggerPostResults] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [clozesRetrieved, setClozesRetrieved] = useState(false);

  //Audio Cache Management
  const [isCacheWarmed, setIsCacheWarmed] = useState(false); // New state to track cache warm-up
  const [audioCache, setAudioCache] = useState({}); // Cache for audio sources
  const [showAskFlashContainer, setShowAskFlashContainer] = useState(false);
  const [conversation, setConversation] = useState([]);
  const inputRef = useRef(null); // Create a ref for the input field
  const progressPercentage = ((currentClozeIndex + 1) / clozes.length) * 100;  // This calculates how much of the flashcards have been seen as a percentage.


  const navigate = useNavigate();

  const handleFinish = () => {
    // setIsTutorialVisible(false);
    setIsTutorialVisible(false);
    console.log('user id in handlefinish: ', userId);
    apiService.setUserEventValue({
      'user_id':userId,
      'event_type': 'cloze_tutorial_completed',
      'event_value': {'completed': true}
    });
    // Handle any completion logic, like storing progress
  };

  const handleNavigateHome = () => {
    navigate('/platform');
  };

  useEffect(() => {

    const savedClozes= localStorage.getItem('clozes');
    const savedCurrentIndex = localStorage.getItem('current_cloze_index');
    const savedResults = localStorage.getItem('cloze_results');

    

    if (savedClozes) setClozes(JSON.parse(savedClozes));
    if (savedCurrentIndex) setCurrentClozeIndex(JSON.parse(savedCurrentIndex));
    if (savedResults) setResults(JSON.parse(savedResults));
    setLoaded(true); // Set loaded state to true after initializing
  }, []);

  useEffect(() => {
    apiService.getUserEventValue({'user_id': userId, 'event_type': 'cloze_tutorial_completed'})
      .then(data => {
        console.log('get user event data: ', data);
        if (data && data.completed === true){
          setIsTutorialVisible(false);
        }
        else {
          setIsTutorialVisible(true);
        }
      });
  }, [userId]);

  useEffect(() => {
    if (loaded) { // Only store to localStorage if loaded is true
      localStorage.setItem('clozes', JSON.stringify(clozes));
      localStorage.setItem('current_cloze_index', JSON.stringify(currentClozeIndex));
      localStorage.setItem('cloze_results', JSON.stringify(results));
    }
  }, [clozes, currentClozeIndex, results, loaded]);

  useEffect(() => {
    if (clozes.length > 0 && !isCacheWarmed) {
      console.log('Warming the cache');
      const firstCloze = clozes[0];
      getAudio(firstCloze._id, '100', audioCache, setAudioCache)
        .then(() => setIsCacheWarmed(true))
        .catch(err => console.error('Error warming the cache:', err));
    }
  }, [clozes, audioCache, isCacheWarmed]); // Depend only on flashcards


  useEffect(() => {
    if (!userId || !loaded) return;


    const savedClozes= localStorage.getItem('clozes');
    const savedCurrentIndex = localStorage.getItem('current_cloze_index');
    const savedResults = localStorage.getItem('cloze_results');

    if (!savedClozes || !savedCurrentIndex || !savedResults ||
      JSON.parse(savedClozes).length === 0 ||
      JSON.parse(savedResults).length === 0) { 
      console.log('No data in localStorage, calling API');
              // const flashcards = await apiService.getClozesForUser({ 'user_id': userId, 'language': 'KR' });

      // //get random clozes for testing purposes
      // apiService.getRandomCloze({'language':'KR'})
      // .then(clozes => {
      //   setClozes(clozes);
      // });

      // get user specific clozes
      apiService.getClozesForUser({ 'user_id': userId, 'language': 'KR' })
        .then(clozes => {
          console.log('the retrieved clozes: ', clozes);
          setClozesRetrieved(true);
          setClozes(clozes);
        });
    } else {
      console.log('Data found in localStorage, no API call needed');
    }
  }, [userId, loaded]);

  useEffect(() => {
    if (clozes.length === 0 && loaded) {
      console.log('No more clozes available');
      // Any additional side-effects, if needed
    }
  }, [clozes, loaded]);

  useEffect(() => {
    // Focus the input field when a new card is shown
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentClozeIndex, isCorrect]); // Re-run when currentClozeIndex or isCorrect changes


  useEffect(() => {
    console.log(results);
  }, [results]);

  useEffect(() => {
    console.log(currentClozeIndex);
    console.log(clozes[currentClozeIndex]);
  }, [currentClozeIndex, clozes]);



  const decomposeKorean = (text) => {
    return text.split('').flatMap(decomposeHangul);
  };

  const decomposeHangul = (syllable) => {
    if (syllable.length !== 1) return syllable;
  
    const baseCode = syllable.charCodeAt(0);
    const choSimple = [
      'ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ',
      'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'
    ];
    const jungSimple = [
      'ㅏ', 'ㅐ', 'ㅑ', 'ㅒ', 'ㅓ', 'ㅔ', 'ㅕ', 'ㅖ', 'ㅗ', 'ㅘ', 'ㅙ',
      'ㅚ', 'ㅛ', 'ㅜ', 'ㅝ', 'ㅞ', 'ㅟ', 'ㅠ', 'ㅡ', 'ㅢ', 'ㅣ'
    ];
    const jongSimple = [
      '', 'ㄱ', 'ㄲ', 'ㄳ', 'ㄴ', 'ㄵ', 'ㄶ', 'ㄷ', 'ㄹ', 'ㄺ', 'ㄻ',
      'ㄼ', 'ㄽ', 'ㄾ', 'ㄿ', 'ㅀ', 'ㅁ', 'ㅂ', 'ㅄ', 'ㅅ', 'ㅆ', 'ㅇ',
      'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'
    ];
  
    const jongDecompose = {
      'ㄳ': ['ㄱ', 'ㅅ'],
      'ㄵ': ['ㄴ', 'ㅈ'],
      'ㄶ': ['ㄴ', 'ㅎ'],
      'ㄺ': ['ㄹ', 'ㄱ'],
      'ㄻ': ['ㄹ', 'ㅁ'],
      'ㄼ': ['ㄹ', 'ㅂ'],
      'ㄽ': ['ㄹ', 'ㅅ'],
      'ㄾ': ['ㄹ', 'ㅌ'],
      'ㄿ': ['ㄹ', 'ㅍ'],
      'ㅀ': ['ㄹ', 'ㅎ'],
      'ㅄ': ['ㅂ', 'ㅅ']
    };
  
    const jungDecompose = {
      'ㅘ': ['ㅗ', 'ㅏ'],
      'ㅙ': ['ㅗ', 'ㅐ'],
      'ㅚ': ['ㅗ', 'ㅣ'],
      'ㅝ': ['ㅜ', 'ㅓ'],
      'ㅞ': ['ㅜ', 'ㅔ'],
      'ㅟ': ['ㅜ', 'ㅣ'],
      'ㅢ': ['ㅡ', 'ㅣ']
    };
  
    if (baseCode < 0xAC00 || baseCode > 0xD7A3) return syllable;
  
    const base = baseCode - 0xAC00;
    const cho = Math.floor(base / (21 * 28));
    const jung = Math.floor((base % (21 * 28)) / 28);
    const jong = base % 28;
  
    const choJamo = choSimple[cho];
    const jungJamo = jungSimple[jung];
    const jongJamo = jongSimple[jong];
  
    const decomposedJong = jongDecompose[jongJamo] || [jongJamo];
    const decomposedJung = jungDecompose[jungJamo] || [jungJamo];
  
    return [choJamo, ...decomposedJung, ...decomposedJong].filter(j => j !== '');
  };

  const handleChange = (event) => {
    if (isCorrect) return; // Prevent further input once the answer is correct

    const value = event.target.value;
    setUserInput(value);

    const decomposedInput = decomposeKorean(value);
    const decomposedTarget = decomposeKorean(clozes[currentClozeIndex]?.masked_characters);
    const isExactMatch = decomposedInput.join('') === decomposedTarget.join('');

    let isForgettingSpace = false;

    // Check for match with spaces ignored only if the exact match is false
    if (!isExactMatch) {
      isForgettingSpace = decomposedInput.join('').replace(/\s/g, '') === decomposedTarget.join('').replace(/\s/g, '');
      setSpaceError(isForgettingSpace); // Set space error state if true
    } else {
      setSpaceError(false); // Reset space error if exact match
    }

    setIsCorrect(isExactMatch);

    const isMatch = decomposedInput.length > 0 && decomposedTarget.slice(0, decomposedInput.length).every((char, index) => char === decomposedInput[index]);
    setIsCorrectSoFar(isMatch);
  };

  const revealAnswer = () => {
    setUserInput(clozes[currentClozeIndex].masked_characters);
    setIsCorrect(false);
    setIsAnswerRevealed(true);
    // updateResults(false);
    setSpaceError(false); // Reset space error when revealing answer

  };

  const postClozeResults = useCallback(async () => {
    console.log('progression_results: ', results);
    setCalculatingResults(true);
    apiService.postClozeFlashcardResults({'user_id': userId, 'language': 'KR', 'results': results})
    .then(data => {
      console.log('proficiency_data: ', data);
      setPostResult(data);
      setShowResultBox(true);
      setCalculatingResults(false);
    })
    .catch(error => {
      console.error('Failed to post cloze results:', error);
    })
  }, [results, userId]);  // Add necessary dependencies

  const clearStorage = useCallback(() => {
    localStorage.removeItem('clozes');
    localStorage.removeItem('current_cloze_index');
    localStorage.removeItem('cloze_results');
  }, []);

  const handleAskFlashClick = () => {
    setShowAskFlashContainer(true); // Show the AskFlash conversation
  };

  const lessonCompleted = useCallback(async () => {
    console.log('into lessonCompleted');
    await postClozeResults();
    clearStorage();
  }, [postClozeResults, clearStorage]);

  const continueAfterResultsUpdate = useCallback((updatedResults) => {
    // Logic that depends on the updated results
    console.log(updatedResults);
    if (currentClozeIndex < clozes.length - 1) {
      setCurrentClozeIndex(currentClozeIndex + 1);
      setUserInput('');
      setIsCorrect(false);
      setIsAnswerRevealed(false);
      setSpaceError(false); // Reset space error when revealing answer
    } else {
      lessonCompleted();
    }
  }, [currentClozeIndex, clozes.length, lessonCompleted]);

  const updateResults = useCallback((completedCorrectly) => {

    setResults(prevResults => {
      const updatedResults = [
        ...prevResults,
        {
          masked_target_id: clozes[currentClozeIndex].masked_target_id,
          completedCorrectly
        }
      ];

      // Pass the updated results to the next function
      continueAfterResultsUpdate(updatedResults);

      // Return the updated results to update the state
      return updatedResults;
    });
  }, [setResults, clozes, currentClozeIndex, continueAfterResultsUpdate]);

  const handleCorrect = useCallback(() => {
    const completedCorrectly = !isAnswerRevealed;
    try {
      // Check if the conversation has any content
      if (conversation.length > 0) {
        // Await the post request to ensure it finishes before clearing the conversation
        apiService.postAskFlashConversation({
          user_id: userId,
          exercise_type: "cloze",
          exercise_data: clozes[currentClozeIndex],
          conversation: conversation,
      });
        // Clear the conversation after the API call is complete
        setConversation([]);
        clearAudioCache(setAudioCache); // Clear audio cache for next flashcard
      }
    } catch (error) {
      console.error('Error posting AskFlash conversation:', error);
    }
  
    // Pass the completedCorrectly to updateResults
    updateResults(completedCorrectly);
  }, [isAnswerRevealed, updateResults, conversation, clozes, currentClozeIndex, userId]);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && isCorrect) {
        handleCorrect();
      }
    };

    if (isCorrect) {
      window.addEventListener('keydown', handleKeyDown);
    }

    // Clean up the event listener when the component unmounts or when isCorrect changes
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isCorrect, handleCorrect]); // Only re-run this effect when isCorrect changes

  useEffect(() => {
    if (triggerPostResults) {
      postClozeResults(); // This will run after the state is updated
      setTriggerPostResults(false); // Reset the trigger
    }
  }, [triggerPostResults, postClozeResults, setTriggerPostResults]); // Dependency array includes results and triggerPostResults
  

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && isCorrect) {
      handleCorrect();
    }
  };

  // if (clozes.length === 0) {
  //   return <div>Retrieving Optimal Sentences for the User...</div>;
  // }

  const currentCloze = clozes[currentClozeIndex] || {}; // Safe access to the current cloze

  const leadingChars = currentCloze?.leading_chars || '';
  const trailingChars = currentCloze?.trailing_chars || '';
  const maskedHangul = currentCloze?.masked_hangul || '';


  const inputWidth = `${(currentCloze.masked_characters?.length || 1) + 4}ch`;
  // const inputWidth = `${(clozes[currentClozeIndex].masked_characters?.length || 1) + 2}ch`;
  const parts = clozes[currentClozeIndex] && clozes[currentClozeIndex].masked_sentence ? clozes[currentClozeIndex].masked_sentence.split('_____') : ['', ''];

  return (
    <div>
      {calculatingResults ? (
        <div>Updating User Proficiency...</div>
      ) : showResultBox ? (
        <FlashcardResultsScreen data={postResult} handleNavigateHome={handleNavigateHome} />
      ) : clozes.length === 0 && !clozesRetrieved ? (
        <div>Retrieving Optimal Sentences for the User...</div>
      ) : clozes.length === 0  && clozesRetrieved ?  ( 
        <div className="no-clozes-container">
          <h2 className="text-xl font-semibold mb-4">All Lessons Completed</h2>
          <p className="text-gray-700">
            You have been introduced to all vocabulary and grammar for which we have data. 
            In addition, you have fully reviewed all vocabulary and grammar.
          </p>
          <p className="text-gray-700 mt-2">
            More cards will show up for review as days pass. We also plan to add more vocabulary 
            and grammar content by the end of 2024.
          </p>
          <button
            className="custom-button mt-4"
            onClick={() => navigate('/platform')}
          >
            Back to Home
          </button>
        </div>
      ) : (
    <div className="flashcard-background-container">
      <button className="custom-button" onClick={lessonCompleted} style={{ marginRight: '10px' }}>End Lesson</button>
      <button
        className="custom-button"
        onClick={() => setIsTutorialVisible((prev) => !prev)}
      >
        Tutorial
      </button>
      {isTutorialVisible && (
        <div className="bg-white p-4 my-4 rounded-lg border">
          <TutorialCarousel tutorialName="cloze" onFinish={handleFinish} />
          <p className="underline font-semibold">Tutorial</p>
          <p>Fill in the blank.</p>
          <p>This exercise only affects your 'cloze' proficiency.</p>
        </div>
      )}
      <div className="progress-bar-container">
              <div className="progress-bar">
              <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
              </div>
              <div className="progress-text">{currentClozeIndex}/{clozes.length}</div>
          </div>
      <div className="flashcard-container">
        <p className="text-gray-700 mb-2">
            {parts[0]}
            {isCorrect || isAnswerRevealed ? (
                <span
                  className={`inline-block border-b-4 ${
                    isAnswerRevealed
                      ? 'border-blue-500 text-blue-500'
                      : isCorrectSoFar
                      ? 'border-green-500 text-green-500'
                      : 'border-black'
                  }`}
                >
                {maskedHangul}
              </span>
          ) : (
            <span className="inline-block border-b-4 border-black">
              <span>{leadingChars}</span>
              <input
                type="text"
                ref={inputRef} // Attach the ref to the input field
                value={userInput}
                onChange={handleChange}
                onKeyDown={handleKeyDown} // Listen for the Enter key press
                className={`inline-block outline-none transition duration-200 ${isCorrectSoFar ? 'text-green-500' : 'text-black'}`}
                placeholder=""
                style={{ width: inputWidth, backgroundColor: 'transparent', border: 'none' }}
              />
              <span>{trailingChars}</span>
            </span>
          )}
          {parts[1]}
        </p>
        {spaceError && (
          <div className="popup">
            Check your spaces
          </div>
        )}
        {clozes[currentClozeIndex].underlined ? (
          <p
            className="text-lg text-gray-500 mb-4"
            dangerouslySetInnerHTML={{ __html: `Translation: ${clozes[currentClozeIndex].underlined.underlined_translation}` }}
          />
        ) : (
          <p className="text-lg text-gray-500 mb-4">Translation: {clozes[currentClozeIndex].translation}</p>
        )}
        {clozes[currentClozeIndex].alternatives_and_guidance && (
          <p className="text-lg text-gray-500 mb-4">Guidance: {clozes[currentClozeIndex].alternatives_and_guidance.guidance}</p>
        )}

        {isCorrect && (
          <button
            onClick={handleCorrect}
            className="inline-block px-4 py-2 text-lg text-center text-white transition duration-300 bg-green-600 rounded-md hover:bg-green-700"
          >
            Correct! Next Sentence.
          </button>
        )}
        {!isCorrect && isAnswerRevealed && (
          <button
            onClick={handleCorrect}
            className="inline-block px-4 py-2 text-lg text-center text-white transition duration-300 bg-green-600 rounded-md hover:bg-green-700"
          >
            Next Sentence.
          </button>
        )}
        {!isCorrect && !isAnswerRevealed && (
          <div>
            <button
              onClick={revealAnswer}
              className="inline-block px-4 py-2 text-lg text-center text-white transition duration-300 bg-blue-600 rounded-md hover:bg-blue-700"
            >
              Show the Answer
            </button>
          </div>
        )}
        {(isCorrect || isAnswerRevealed) && (
          <div className="flex justify-center items-center space-x-4 mt-4 cursor-pointer">
            <PlaySound flashcardId={clozes[currentClozeIndex]._id} />
          </div>
        )}
        <button
          type="button"
          className="hover:bg-transparent bg-transparent text-blue-500 underline block mt-4 text-center focus:outline-none"
          onClick={handleAskFlashClick}
        >
          Confused? <br /> Ask Flash
        </button>
      </div>
      {showAskFlashContainer && (
        <div className="bg-white p-5 my-4 rounded-lg border">
          <AskFlash
            userId={userId}
            exerciseType="cloze"
            exerciseData={clozes[currentClozeIndex]}
            conversation={conversation}
            setConversation={setConversation}
          />
        </div>
      )}
      <div className="flashcard-container">
          {clozes[currentClozeIndex].alternatives_and_guidance && (isCorrect || isAnswerRevealed) && (
        <div className="bg-gray-100 p-5 rounded-lg">
            <h3 className="text-xl text-gray-800 font-bold mb-4">Definition:</h3>
            <div className="flex items-start mb-4">
                <span className="font-semibold text-gray-900 mr-3 min-w-[50px] whitespace-nowrap">{clozes[currentClozeIndex].masked_target}:</span>
                {clozes[currentClozeIndex].alternatives_and_guidance.masked && ( <p className="text-gray-700 flex-1">{Object.values(clozes[currentClozeIndex].alternatives_and_guidance.masked)[0]}</p>)}
            </div>
            
            {/* <h3 className="text-xl text-gray-800 font-bold mb-4">Alternatives:</h3> */}
            <ul className="space-y-4">
                {/* {Object.entries(clozes[currentClozeIndex].alternatives_and_guidance.alternatives).map(([word, explanation], index) => (
                    <li key={index} className="flex items-start">
                        <span className="font-semibold text-gray-900 mr-3 min-w-[50px] whitespace-nowrap">{word}:</span>
                        <p className="text-gray-700 flex-1">{explanation}</p>
                    </li>
                ))} */}
                {clozes[currentClozeIndex].alternatives_and_guidance.alternatives && 
                  Object.keys(clozes[currentClozeIndex].alternatives_and_guidance.alternatives).length > 0 && (
                      <>
                          <h3 className="text-xl text-gray-800 font-bold mb-4">Alternatives:</h3>
                          <ul className="space-y-4">
                              {Object.entries(clozes[currentClozeIndex].alternatives_and_guidance.alternatives).map(([word, explanation], index) => (
                                  <li key={index} className="flex items-start">
                                      <span className="font-semibold text-gray-900 mr-3 min-w-[50px] whitespace-nowrap">{word}:</span>
                                      <p className="text-gray-700 flex-1">{explanation}</p>
                                  </li>
                              ))}
                          </ul>
                      </>
                  )
              }
            </ul>
        </div>
    )}
      </div>
    </div>)}</div>
  );
}

export default ClozeExercise;
