import React, { useState } from 'react';
import SetProficiencyLevel from './SetProficiencyLevel';
import CollapsibleContainer from './CollapsibleContainer';


function UserSettings() {
  const [initializeProficiency, setInitializeProficiency] = useState(false);

  return (
    <div>
      <h1>User Settings</h1>
      <CollapsibleContainer 
        title="Set/Adjust Proficiency Level" 
        onToggle={(isOpen) => setInitializeProficiency(isOpen)}
      >
        <SetProficiencyLevel initialize={initializeProficiency} />
      </CollapsibleContainer>
    </div>
  );
}

export default UserSettings;