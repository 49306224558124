import React, { useState, useEffect } from 'react';

const TutorialCarousel = ({ tutorialName, onFinish }) => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Dynamically load slides based on tutorialName
  useEffect(() => {
    let slideCount = 3; // Default slide count

    if (tutorialName === "reading_flashcard") {
        slideCount = 6; // Specific slide count for "reading_flashcard"
    }
    if (tutorialName === "listening_flashcard") {
        slideCount = 5; // Specific slide count for "reading_flashcard"
    }
    if (tutorialName === "cloze") {
        slideCount = 3; // Specific slide count for "cloze"
    }

    const loadedSlides = [];

    for (let i = 1; i <= slideCount; i++) {
        // Construct the image path based on the tutorialName and slide number
        loadedSlides.push(`/tutorials/${tutorialName}/slide${i}.png`);
    }

    setSlides(loadedSlides);
    }, [tutorialName]);

  const goNext = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const goBack = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//       <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/3 p-6">
//         {slides.length > 0 ? (
//           <>
//             <img
//               src={slides[currentSlide]}
//               alt={`Slide ${currentSlide + 1}`}
//               className="w-full h-auto mb-4"
//             />
//             <div className="flex justify-between">
//               <button
//                 className="bg-gray-300 py-2 px-4 rounded hover:bg-gray-400"
//                 onClick={goBack}
//                 disabled={currentSlide === 0}
//               >
//                 Back
//               </button>
//               {currentSlide === slides.length - 1 ? (
//                 <button
//                   className="bg-green-500 py-2 px-4 rounded text-white hover:bg-green-600"
//                   onClick={onFinish}
//                 >
//                   Finish
//                 </button>
//               ) : (
//                 <button
//                   className="bg-blue-500 py-2 px-4 rounded text-white hover:bg-blue-600"
//                   onClick={goNext}
//                 >
//                   Next
//                 </button>
//               )}
//             </div>
//           </>
//         ) : (
//           <p>Loading slides...</p>
//         )}
//       </div>
//     </div>
//   );
// };

return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/3 p-6 max-h-screen max-w-screen overflow-y-auto">
        {slides.length > 0 ? (
          <>
            <img
              src={slides[currentSlide]}
              alt={`Slide ${currentSlide + 1}`}
              className="w-full h-auto mb-4"
            />
            <div className="flex justify-between">
              <button
                className="bg-gray-300 py-2 px-4 rounded hover:bg-gray-400"
                onClick={goBack}
                disabled={currentSlide === 0}
              >
                Back
              </button>
              {currentSlide === slides.length - 1 ? (
                <button
                  className="bg-green-500 py-2 px-4 rounded text-white hover:bg-green-600"
                  onClick={onFinish}
                >
                  Finish
                </button>
              ) : (
                <button
                  className="bg-blue-500 py-2 px-4 rounded text-white hover:bg-blue-600"
                  onClick={goNext}
                >
                  Next
                </button>
              )}
            </div>
          </>
        ) : (
          <p>Loading slides...</p>
        )}
      </div>
    </div>
  );
};

export default TutorialCarousel;