// src/components/WhyFlashFluency.js
import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

const WhyFlashFluency = () => {
    return (
        <section className="bg-white p-8 rounded-lg shadow-lg my-8 border-2 border-black">
            <h2 className="text-3xl font-extrabold mb-6 text-blue-700 text-center">Why Flash Fluency?</h2>
            <ul className="list-none space-y-6">
                <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2"/>
                    <div>
                        <strong className="text-xl font-semibold">+1 Incremental Learning:</strong>
                        <p className="ml-8">All sentences and exercises introduce one new vocabulary or grammar point at a time, tying new concepts into what you’ve already learned for better retention and contextual learning.</p>
                    </div>
                </li>
                <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2"/>
                    <div>
                        <strong className="text-xl font-semibold">Extensive Lessons:</strong>
                        <p className="ml-8">Access over 1,000 vocabulary and 300 grammar mini-lessons. Need clarification? Click on any vocabulary or grammar point for a detailed breakdown of usage.</p>
                    </div>
                </li>
                <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2"/>
                    <div>
                        <strong className="text-xl font-semibold">Pedagogically Focused:</strong>
                        <p className="ml-8">Vocabulary and grammar are introduced in an order conducive with CEFR standards and frequency analysis.</p>
                    </div>
                </li>
                <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2"/>
                    <div>
                        <strong className="text-xl font-semibold">Personalized Learning:</strong>
                        <p className="ml-8">By granularly tracking your language proficiencies, our algorithm balances the introduction and review of content using an SRS-inspired approach.</p>
                    </div>
                </li>
                <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2"/>
                    <div>
                        <strong className="text-xl font-semibold">Comprehensive Skill Development:</strong>
                        <p className="ml-8">Our app currently only focuses on reading and listening skills, but we have plans to introduce sentence construction, pronunciation, and conversational exercises next!</p>
                    </div>
                </li>
                <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2"/>
                    <div>
                        <strong className="text-xl font-semibold">Proficiency Tracking:</strong>
                        <p className="ml-8">Watch your vocabulary, grammar, reading, and listening proficiencies improve over time.</p>
                    </div>
                </li>
            </ul>
        </section>
    );
};

export default WhyFlashFluency;