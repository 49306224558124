

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from './UserContext';
import ApiService from './ApiService';

const apiService = new ApiService();

function Vocabulary() {
  const [vocabularyLessons, setVocabularyLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: 'vocab', direction: 'ascending' });
  const [selectedDifficulties, setSelectedDifficulties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { userId } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const difficultyLevels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const data = await apiService.fetchVocabularyLessonsWithProficiency(userId);
        setVocabularyLessons(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the vocabulary lessons:', error);
        setLoading(false);
      }
    };

    fetchLessons();
  }, [userId, location]);

  const handleCheckboxChange = (difficulty) => {
    if (selectedDifficulties.includes(difficulty)) {
      setSelectedDifficulties(selectedDifficulties.filter((level) => level !== difficulty));
    } else {
      setSelectedDifficulties([...selectedDifficulties, difficulty]);
    }
  };

  const handleSelectAll = () => {
    if (selectedDifficulties.length === difficultyLevels.length) {
      setSelectedDifficulties([]);
    } else {
      setSelectedDifficulties(difficultyLevels);
    }
  };

  const sortedLessons = React.useMemo(() => {
    let sortableLessons = [...vocabularyLessons];

    if (sortConfig !== null) {
      sortableLessons.sort((a, b) => {
        if (sortConfig.key === 'vocab') {
          return a.learning_index - b.learning_index; // Ascending order by learning_index
        } else {
          // Descending order for proficiency
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return -1;
          }
          return 0;
        }
      });
    }

    // Apply search filter
    sortableLessons = sortableLessons.filter((lesson) =>
      lesson.vocab.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Apply difficulty filter
    if (selectedDifficulties.length > 0) {
      sortableLessons = sortableLessons.filter((lesson) =>
        selectedDifficulties.includes(lesson.difficulty)
      );
    }

    return sortableLessons;
  }, [vocabularyLessons, sortConfig, selectedDifficulties, searchTerm]);

  const requestSort = (key) => {
    setSortConfig({ key, direction: key === 'vocab' ? 'ascending' : 'descending' });
  };

  if (loading) {
    return <p className="text-center text-lg text-gray-600">Loading...</p>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Vocabulary</h2>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search vocabulary..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 p-2 border border-gray-300 rounded-md w-full"
      />

      {/* Difficulty Checkboxes */}
      <div className="flex space-x-4 mb-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={selectedDifficulties.length === difficultyLevels.length}
            onChange={handleSelectAll}
            className="form-checkbox h-4 w-4 text-blue-600"
          />
          <span className="ml-2">All Difficulties</span>
        </label>
        {difficultyLevels.map((level) => (
          <label key={level} className="inline-flex items-center">
            <input
              type="checkbox"
              checked={selectedDifficulties.includes(level)}
              onChange={() => handleCheckboxChange(level)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2">{level}</span>
          </label>
        ))}
      </div>

      <table className="table-fixed min-w-full bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-100 text-left text-sm text-gray-600">
            <th className="p-3 w-1/4 max-w-xs" onClick={() => requestSort('vocab')}>
              Vocabulary (sort by freq)
            </th>
            <th className="p-3 w-1/6" onClick={() => requestSort('reading_proficiency')}>
              Reading Proficiency
            </th>
            <th className="p-3 w-1/6" onClick={() => requestSort('listening_proficiency')}>
              Listening Proficiency
            </th>
            <th className="p-3 w-1/6" onClick={() => requestSort('cloze_proficiency')}>
              Cloze Proficiency
            </th>
            <th className="p-3 w-1/6">Difficulty</th>
          </tr>
        </thead>
        <tbody>
          {sortedLessons.map((lesson) => (
            <tr key={lesson.id} className="border-b">
              <td className="p-3 text-gray-800 max-w-xs truncate">
                <span
                  onClick={() => navigate(`/vocabulary/${encodeURIComponent(lesson.vocab)}`)}
                  className="text-blue-600 cursor-pointer hover:underline"
                >
                  {lesson.vocab}
                </span>
              </td>
              <td className="p-3 text-gray-800">{lesson.reading_proficiency}%</td>
              <td className="p-3 text-gray-800">{lesson.listening_proficiency}%</td>
              <td className="p-3 text-gray-800">{lesson.cloze_proficiency}%</td>
              <td className="p-3 text-gray-800">{lesson.difficulty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Vocabulary;