import React, { useState, useEffect, useRef } from 'react';
import ApiService from './ApiService';
import './AskFlash.css'; // Isolated CSS file for AskFlash

const apiService = new ApiService();

function AskFlash({ userId, exerciseType, exerciseData, conversation, setConversation }) {
    const [message, setMessage] = useState('');
    const chatEndRef = useRef(null);

    // Scroll to the bottom of the chat after each new message
    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation]);

    // Function to send a new message to the AI assistant
    const sendMessage = async () => {
        if (message.trim() === '') return; // Don't send empty messages

        // Add user message to conversation
        const updatedConversation = [...conversation, { speaker: 'User', message }];
        setConversation(updatedConversation);

        // Clear input
        setMessage('');

        // Make API call to get the AI assistant response
        try {
            const response = await apiService.askFlash({
                user_id: userId,
                exercise_type: exerciseType,
                exercise_data: exerciseData,
                conversation: updatedConversation,
            });
            console.log('askFlash response: ', response)
            // Append AI assistant's response to the conversation
            setConversation([...updatedConversation, { speaker: 'AI', message: response.data.message }]);
        } catch (error) {
            console.error('Error asking Flash:', error);
        }
    };

    return (
        <div className="ask-flash-container">
            <div className="chat-box">
                {conversation.length > 0 ? (
                    conversation.map((entry, index) => (
                        <div
                            key={index}
                            className={`chat-message ${entry.speaker === 'User' ? 'user-message' : 'ai-message'}`}
                        >
                            <p className="message-text">{entry.message}</p>
                        </div>
                    ))
                ) : (
                    <p className="no-messages">Start a conversation with Flash!</p>
                )}
                {/* Dummy div to ensure scroll to bottom */}
                <div ref={chatEndRef}></div>
            </div>

            <div className="input-box">
            <input
            type="text"
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                e.preventDefault(); // Prevent default Enter key behavior
                sendMessage();
                }
            }}
            />
                <button onClick={sendMessage}>Send</button>
            </div>
        </div>
    );
}

export default AskFlash;
