

// src/components/UserFeedback.js
import React, { useState } from 'react';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/outline';
import ApiService from './ApiService';

const UserFeedback = () => {
    const [rating, setRating] = useState(0); // Default rating is 0
    const [liked, setLiked] = useState('');
    const [suggestions, setSuggestions] = useState('');
    const [feedback, setFeedback] = useState('');
    const [notification, setNotification] = useState('');

    const handleRating = (rate) => {
        setRating(rate);
    };

    const sendUserFeedback = (e) => {
        e.preventDefault();

        const feedbackData = {
            rating,
            liked,
            suggestions,
            feedback,
        };


        const apiService = new ApiService();
        apiService.postUserFeedback({'user_feedback': feedbackData})
            .then(response => {
                // Handle successful response
                console.log('Feedback submitted successfully:', response);
                setNotification('Thank you for your feedback!');
                setTimeout(() => {
                    setNotification('');
                }, 5000); // Hide notification after 5 seconds
            })
            .catch(error => {
                // Handle error
                console.error('Error submitting feedback:', error);
                setNotification('Error submitting feedback. Please try again.');
                setTimeout(() => {
                    setNotification('');
                }, 5000); // Hide notification after 5 seconds
            });
    };

    return (
        <section className="bg-white p-8 rounded-lg shadow-lg my-8 border-2 border-black">
            <h2 className="text-2xl font-extrabold mb-4 text-blue-700 text-center">Tell Us What You Think!</h2>
            <form className="space-y-4" onSubmit={sendUserFeedback}>
                <div>
                    <label className="block mb-2 font-semibold">How would you rate your overall experience?</label>
                    <div className="flex space-x-1 justify-center">
                        {[1, 2, 3, 4, 5].map((star) => (
                            <button
                                type="button"
                                key={star}
                                onClick={() => handleRating(star)}
                                className="focus:outline-none"
                            >
                                {star <= rating ? (
                                    <StarIconSolid className="h-8 w-8 text-yellow-500" />
                                ) : (
                                    <StarIconOutline className="h-8 w-8 text-yellow-500" />
                                )}
                            </button>
                        ))}
                    </div>
                </div>
                <div>
                    <label className="block mb-2 font-semibold">What did you like?</label>
                    <textarea 
                        className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
                        rows="4"
                        value={liked}
                        onChange={(e) => setLiked(e.target.value)}
                    ></textarea>
                </div>
                <div>
                    <label className="block mb-2 font-semibold">Suggestions for Improvement</label>
                    <textarea 
                        className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
                        rows="4"
                        value={suggestions}
                        onChange={(e) => setSuggestions(e.target.value)}
                    ></textarea>
                </div>
                <div>
                    <label className="block mb-2 font-semibold">Open-Ended Feedback</label>
                    <textarea 
                        className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
                        rows="4"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    ></textarea>
                </div>
                <div className="text-center">
                    <button type="submit" className="px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300">
                        Submit
                    </button>
                </div>
            </form>
            {notification && (
                <div className="mt-4 text-center text-green-500 font-semibold">
                    {notification}
                </div>
            )}
        </section>
    );
};

export default UserFeedback;