

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons'; // Import the Discord icon

const DiscordLink = () => {
    return (
        <div className="text-center mt-8">
            <a href="https://discord.gg/M2qgyksd" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faDiscord} size="3x" className="text-blue-500" />
            </a>
            <p className="mt-2 text-sm text-gray-600">Join our Discord community!</p>
        </div>
    );
};

export default DiscordLink;