import ApiService from './ApiService';
const apiService = new ApiService();

/**
 * Function to fetch audio, caching the result per flashcardId and speed.
 * Clears the cache if the flashcardId changes.
 */
export const getAudio = async (flashcardId, speed, audioCache, setAudioCache) => {
  console.log('into get audio')
  const cacheKey = `${flashcardId}-${speed}`;

  // Check if audio is already cached
  if (audioCache[cacheKey]) {
    console.log('grabbed audio from cache')
    return audioCache[cacheKey]; // Return cached audio source
  }

  // Fetch new audio source from API if not cached
  console.log('had to grab audio from api')
  const audioSrc = await apiService.getAudioSrc(flashcardId, speed);

  // Cache the audio source for this flashcard and speed
  setAudioCache((prevCache) => ({
    ...prevCache,
    [cacheKey]: audioSrc,
  }));

  return audioSrc; // Return the new audio source
};

/**
 * Function to clear the audio cache. Call this when moving to a new flashcard.
 */
export const clearAudioCache = (setAudioCache) => {
  console.log('clear audio cache')
  setAudioCache({}); // Reset cache to an empty object
};