// src/components/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>
            <p className="text-center mb-8">Last Updated: 7/31/2024</p>
            <section className="bg-white p-8 rounded-lg shadow-lg border-2 border-gray-300">
                <p className="text-blue-700 mb-5">In a nutshell: We track the unique ID associated with whatever social media account you login with. We track legitimately nothing else.</p>

                <h2 className="text-2xl font-semibold mb-4">Welcome to FlashFluency!</h2>
                <p className="mb-4">At FlashFluency, we prioritize your privacy and are committed to ensuring that your personal information remains secure and confidential. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our services.</p>

                <h3 className="text-xl font-semibold mb-2">1. No Data Collection</h3>
                <p className="mb-4">We do not collect, store, or process any personal data from our users. Your privacy is our top priority, and we ensure that your information remains completely anonymous and confidential while you interact with our services.</p>

                <h3 className="text-xl font-semibold mb-2">2. Usage Data</h3>
                <p className="mb-4">While using our service, we may collect limited technical information to improve the performance and user experience. This information includes:</p>
                <ul className="list-disc list-inside mb-4">
                    <li>IP Address</li>
                    <li>Browser Type and Version</li>
                    <li>Device Information</li>
                    <li>Pages Visited</li>
                    <li>Time and Date of Visit</li>
                </ul>
                <p className="mb-4">This data is collected in aggregate form and does not personally identify you.</p>

                <h3 className="text-xl font-semibold mb-2">3. Cookies and Tracking Technologies</h3>
                <p className="mb-4">We do not use cookies or any tracking technologies that store or track your personal information. Any temporary data used for session management is discarded once your session ends.</p>

                <h3 className="text-xl font-semibold mb-2">4. Third-Party Services</h3>
                <p className="mb-4">We do not share or disclose your information to third-party services. Since we do not collect any personal data, there is nothing to share or disclose.</p>

                <h3 className="text-xl font-semibold mb-2">5. Data Security</h3>
                <p className="mb-4">Since we do not collect any personal data, there are no security measures required for data storage. However, we employ industry-standard security measures to protect our platform and ensure a safe browsing experience for our users.</p>

                <h3 className="text-xl font-semibold mb-2">6. Changes to This Privacy Policy</h3>
                <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;