class ApiService {
    constructor() {
      this.baseUrl = this.getBaseUrl();
    }
  
    getBaseUrl() {
      const FF_ENV = process.env.REACT_APP_FF_ENV || 'dev'; 
      console.log('FF_ENV', FF_ENV);
      if (FF_ENV === "dev") {
        return "http://127.0.0.1:8000";
      } else if (FF_ENV === "test") {
        return "https://flashfluency-rest-test.azurewebsites.net";
      } else {
        throw new Error('Invalid environment configuration');
      }
    }
  
    fetchListeningFlashcardsForUser(userId) {
      return this.performPost(`${this.baseUrl}/retrieve_listening_flashcards_for_user/`, { user_id: userId });
    }
  
    fetchReadingFlashcardsForUser(userId) {
      return this.performPost(`${this.baseUrl}/retrieve_reading_flashcards_for_user/`, { user_id: userId });
    }
  
    postListeningFlashcardResults(data) {
      return this.performPost(`${this.baseUrl}/post_listening_flashcard_results/`, data);
    }
  
    postReadingFlashcardResults(data) {
      return this.performPost(`${this.baseUrl}/post_reading_flashcard_results/`, data);
    }

    postClozeFlashcardResults(data) {
      return this.performPost(`${this.baseUrl}/post_cloze_results/`, data);
    }

    fetchGrammarLessonsWithProficiency(userId) {
      return this.performPost(`${this.baseUrl}/get_grammar_lessons_with_proficiency/`, {user_id: userId});
    }

    fetchGrammarLessonById(data) {
      return this.performPost(`${this.baseUrl}/get_grammar_lesson_by_id/`, data);
    }
    

    fetchVocabularyLessonsWithProficiency(userId) {
      return this.performPost(`${this.baseUrl}/get_vocab_lessons_with_proficiency/`, {user_id: userId});
    }

    fetchVocabLesson(data){
      return this.performPost(`${this.baseUrl}/get_vocab_lesson/`, data);
    }
  
    getAudioSrc(flashcardId, speed) {
      return `${this.baseUrl}/static/${flashcardId}-${speed}.wav`;
    }

    get_or_create_user_from_google_unique_id(google_unique_id) {
      return this.performPost(`${this.baseUrl}/get_or_create_user_from_google_unique_id/`, {'google_unique_id':google_unique_id});
    }

    postFlashcardDeveloperReview(data) {
      return this.performPost(`${this.baseUrl}/post_flashcard_dev_review/`, data);
    }

    fetchUserStatistics(data){
      return this.performPost(`${this.baseUrl}/get_user_stats/`, data);
    }

    fetchProficiencyPlacementVocabulary(data){
      return this.performPost(`${this.baseUrl}/get_proficiency_placement_vocabulary/`, data);
    }

    getUserProficiencyPlacement(data){
      return this.performPost(`${this.baseUrl}/get_user_proficiency_placement/`, data);
    }

    setUserProficiencyPlacement(data){
      return this.performPost(`${this.baseUrl}/set_user_proficiency_placement/`, data);
    }

    postUserFeedback(data) {
      return this.performPost(`${this.baseUrl}/save_user_feedback/`, data);
    }

    getRandomCloze(data) {
      return this.performPost(`${this.baseUrl}/get_random_cloze/`, data);
    }

    getClozesForUser(data) {
      return this.performPost(`${this.baseUrl}/get_cloze_flashcards_for_user/`, data);
    }

    askFlash(data){
      return this.performPost(`${this.baseUrl}/ask_flash/`, data);
    }

    postAskFlashConversation(data){
      return this.performPost(`${this.baseUrl}/upsert_ask_flash_conversation/`, data);
    }

    testGetRandomFlashcards(data) {
      return this.performPost(`${this.baseUrl}/test_get_random_flashcards/`,data);
    }

    getUserEventValue(data) {
      return this.performPost(`${this.baseUrl}/get_user_event_value/`,data);
    }

    setUserEventValue(data) {
      return this.performPost(`${this.baseUrl}/set_user_event_value/`,data);
    }



  
    // performPost(url, data) {
    //   return fetch(url, {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify(data)
    //   }).then(res => res.json())
    //     .catch(error => console.error('Fetching error:', error));
    // }

    performPost(url, data) {
      return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(jsonResponse => {
        // Check if the response has a 'data' field and return it
        if (jsonResponse && jsonResponse.data) {
          return jsonResponse.data;
        } else {
          throw new Error('Malformed response: no "data" field found');
        }
      })
      .catch(error => {
        console.error('Fetching error:', error);
        throw error;
      });
    }

  }
  
  export default ApiService;
  