// VocabLesson.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ApiService from './ApiService';
import './VocabLesson.css'; // Import the CSS file for styling

const apiService = new ApiService();

function VocabLesson({ vocabWord: propVocabWord }) {
  const { vocabWord: paramVocabWord } = useParams();
  const vocabWord = propVocabWord || paramVocabWord;


  const [lesson, setLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMeaningIndex, setSelectedMeaningIndex] = useState(null);

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const data = await apiService.fetchVocabLesson({ vocab: vocabWord, source_language: "EN", target_language: "KR" });
        setLesson(data); // Directly set lesson_dict
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the lesson:', error);
        setLoading(false);
      }
    };
    fetchLesson();
  }, [vocabWord]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!lesson) {
    return <p>Lesson not found</p>;
  }

  const { word, meanings } = lesson;

  if (!meanings) {
    return <p>This Vocab Lesson does not exist yet.</p>;
  }

  const handleMeaningClick = (index) => {
    setSelectedMeaningIndex(index === selectedMeaningIndex ? null : index);
  };

  return (
    <div className="lesson-container">
      <h2>{word}</h2>
      <div className="meanings">
        <strong>Meanings:</strong>
        <ul>
          {meanings.map((meaning, index) => (
            <li key={index}>
              <span className="meaning" onClick={() => handleMeaningClick(index)}>
                {meaning.definition.source_language}
              </span>
              {selectedMeaningIndex === index && (
                <div className="details active">
                  {/* {meaning.parts_of_speech && (
                    <div className="detail-section">
                      <strong>Parts of Speech:</strong>
                      <ul>
                        {Object.entries(meaning.parts_of_speech).map(([pos, example]) => (
                          <li key={pos}>{pos}: {example}</li>
                        ))}
                      </ul>
                    </div>
                  )} */}
                  {meaning.additional_key_information && (
                    <div className="detail-section">
                      <strong>Additional Information:</strong>
                      <p>{meaning.additional_key_information}</p>
                    </div>
                  )}
                  {meaning.contexts_for_use && (
                    <div className="detail-section">
                    <strong>Use Cases:</strong>
                    {meaning.contexts_for_use.map((context, idx) => (
                        <div key={idx} className="use-case">
                          <p className="context"><u>{context.context.source_language}</u></p>
                          <p className="example">{context.example.target_language}</p>
                          <p className="example">{context.example.source_language}</p>
                        </div>
                      ))}
                  </div>
                  )}
                  {/* {meaning.synonyms && (
                    <div className="detail-section">
                      <strong>Synonyms:</strong>
                      <ul>
                        {meaning.synonyms.map((synonym, idx) => (
                          <li key={idx}>{synonym.word}: {synonym.meaning} ({synonym.usage})</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {meaning.antonyms && (
                    <div className="detail-section">
                      <strong>Antonyms:</strong>
                      <ul>
                        {meaning.antonyms.map((antonym, idx) => (
                          <li key={idx}>{antonym.word}: {antonym.meaning}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {meaning.confounding_words && (
                    <div className="detail-section">
                      <strong>Similar Words:</strong>
                      <ul>
                        {meaning.confounding_words.map((confound_word, idx) => (
                          <li key={idx}><strong>{confound_word.word}</strong><br /> {confound_word.meaning}<br />{confound_word.explanation}</li>
                          
                        ))}
                      </ul>
                    </div>
                  )} */}
                  {meaning.example_dialogues && (
                    <div className="detail-section">
                      <strong>Example Dialogue:</strong>
                      {meaning.example_dialogues.map((dialogue, idx) => (
                        <div key={idx} className="dialogue-item">
                          {dialogue.dialogue.map((line, lineIdx) => (
                            <p key={lineIdx}><strong>{line.speaker}:</strong> {line.sentence.target_language} ({line.sentence.source_language})</p>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                  {meaning.collocations && (
                    <div className="detail-section">
                      <strong>Common Word Combinations:</strong>
                      <ul>
                        {meaning.collocations.map((collocation, idx) => (
                          <li key={idx}>{collocation.phrase.target_language}: {collocation.phrase.source_language}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default VocabLesson;