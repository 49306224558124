import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis, VictoryLabel, VictoryLegend } from 'victory';

import ApiService from './ApiService';
const apiService = new ApiService();

const vocabularyThresholds = [
  { limit: 20, label: '', color: 'white' },
  { limit: 50, label: '', color: 'white' },
    { limit: 100, label: '', color: 'white' },
    { limit: 500, label: 'A1', color: 'grey' },
    { limit: 1000, label: 'A2', color: 'grey' },
    { limit: 2000, label: 'B1', color: 'grey' },
    { limit: 3000, label: 'B2', color: 'grey' }
  ];
  
  const grammarThresholds = [
    { limit: 20, label: '', color: 'white' },
    { limit: 50, label: 'A1', color: 'grey' },
    { limit: 100, label: 'A2', color: 'grey' },
    { limit: 150, label: 'B1', color: 'grey' },
    { limit: 200, label: 'B2', color: 'grey' }
  ];

  const combinedThresholds = [
    { limit: 20, label: '', color: 'white' },
    { limit: 50, label: '', color: 'white' },
    { limit: 100, label: '', color: 'white' },
    { limit: 550, label: 'A1', color: 'grey' },
    { limit: 1100, label: 'A2', color: 'grey' },
    { limit: 2150, label: 'B1', color: 'grey' },
    { limit: 3200, label: 'B2', color: 'grey' }
  ]

  const periods = {
    daily: 1,
    weekly: 7,
    monthly: 30,
    allTime: 'all',
  };
  

function Statistics() {
  const [statistics, setStatistics] = useState(null);
  const { userId, language } = useUser();
  // const [todayLearning, setTodayLearning] = useState(null); // Store today's learning summary
  const navigate = useNavigate();
  const [learningSummary, setLearningSummary] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('daily'); // Default period


  const calculateLearning = useCallback((data) => {
    if (!data) return;

    const getLearningForPeriod = (stats, key) => {
      console.log('getlearningforperiod stats: ', stats);
      if (!stats || !stats.timestamp) {
        console.error("Invalid stats or missing timestamp:", stats);
        return 0;
      }
      

      console.log(stats.timestamp)
      // Convert timestamps and values to arrays for proper array-like access
      const timestamps = Object.values(stats.timestamp).map(dateString => new Date(dateString));
      const values = Object.values(stats[key]);
      console.log('timestamps: ', timestamps);
      console.log('values: ', values);
    
      if (timestamps.length === 0 || values.length === 0) {
        console.error(`No valid data found for key: ${key}`);
        return 0;
      }
    
      const today = new Date();
      const periodDays = periods[selectedPeriod];
    
      // If the selected period is 'all', return the most recent value (as data is cumulative)
      if (periodDays === 'all') {
        const latestValue = values[values.length - 1] || 0;
        console.log(`All period selected. Returning latest value for ${key}: ${latestValue}`);
        return latestValue;
      }
    
      // Calculate the start date of the period of interest
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - periodDays);
    
      // Find the latest timestamp and corresponding value
      const latestTimestamp = timestamps[timestamps.length - 1];
      const latestValue = values[values.length - 1] || 0;
    
      console.log(`Latest Timestamp: ${latestTimestamp}, Start Date: ${startDate}, Latest Value: ${latestValue}`);
    
      // If the latest timestamp is before the start date, there's no data in the period
      if (latestTimestamp < startDate) {
        console.log(`No valid data in the selected period for ${key}.`);
        return 0;
      }
    
      // Find the value just before the period
      let prePeriodTimestamp = null;
      let prePeriodValue = null;
      for (let i = timestamps.length - 1; i >= 0; i--) {
        const timestamp = timestamps[i];
        if (timestamp < startDate) {
          prePeriodValue = values[i] || 0;
          prePeriodTimestamp = timestamps[i];
          break;
        }
      }
      console.log('prePeriodTimestamp: ', prePeriodTimestamp);
      console.log('prePeriodValue: ', prePeriodValue);
    
      // If there's no pre-period value, it means no data before the start date
      if (prePeriodValue === null) {
        console.log(`No data before the start date for ${key}.`);
        return 0;
      }
    
      console.log(`Returning difference for ${key}: ${latestValue - prePeriodValue}`);
      return latestValue - prePeriodValue;
    };

    const calculateSummary = (data) => {
  
      return {
        reading: {
          learned:
            getLearningForPeriod(data['reading_stats'], 'reading_vocab_learned') +
            getLearningForPeriod(data['reading_stats'], 'reading_grammar_learned'),
          proficient:
            getLearningForPeriod(data['reading_stats'], 'reading_vocab_proficient') +
            getLearningForPeriod(data['reading_stats'], 'reading_grammar_proficient'),
          mastered:
            getLearningForPeriod(data['reading_stats'], 'reading_vocab_mastered') +
            getLearningForPeriod(data['reading_stats'], 'reading_grammar_mastered'),
        },
        listening: {
          learned:
            getLearningForPeriod(data['listening_stats'], 'listening_vocab_learned') +
            getLearningForPeriod(data['listening_stats'], 'listening_grammar_learned'),
          proficient:
            getLearningForPeriod(data['listening_stats'], 'listening_vocab_proficient') +
            getLearningForPeriod(data['listening_stats'], 'listening_grammar_proficient'),
          mastered:
            getLearningForPeriod(data['listening_stats'], 'listening_vocab_mastered') +
            getLearningForPeriod(data['listening_stats'], 'listening_grammar_mastered'),
        },
        cloze: {
          learned:
            getLearningForPeriod(data['cloze_stats'], 'cloze_vocab_learned') +
            getLearningForPeriod(data['cloze_stats'], 'cloze_grammar_learned'),
          proficient:
            getLearningForPeriod(data['cloze_stats'], 'cloze_vocab_proficient') +
            getLearningForPeriod(data['cloze_stats'], 'cloze_grammar_proficient'),
          mastered:
            getLearningForPeriod(data['cloze_stats'], 'cloze_vocab_mastered') +
            getLearningForPeriod(data['cloze_stats'], 'cloze_grammar_mastered'),
        },
      };
    };

    return calculateSummary(data);
  }, [selectedPeriod]);

  useEffect(() => {
  const fetchStatistics = async () => {
    try {
      const data = await apiService.fetchUserStatistics({ user_id: userId, language });
      console.log('stats fetched: ', data);
      setStatistics(data);
      
      // Calculate learning summary right after stats are fetched
      const calculatedSummary = calculateLearning(data);
      setLearningSummary(calculatedSummary);  // Store the calculated data

      console.log('Learning summary calculated and stored: ', calculatedSummary);
    } catch (error) {
      console.error('Error fetching user statistics:', error);
      navigate('/error');
    }
  };

  fetchStatistics();
  }, [userId, language, navigate, calculateLearning]);

// This effect just updates the UI (e.g., table) when selectedPeriod or learningSummary changes
  useEffect(() => {
    if (learningSummary) {
      console.log('Learning summary for period:', selectedPeriod, learningSummary);
      // Use learningSummary to update your table or UI component
    }
  }, [selectedPeriod, learningSummary]);

  if (!statistics) {
    return <div>Loading...</div>;
  }

  const prepareData = (stats, type, category) => {
    if (!stats || !stats.timestamp) {
      return [];
    }
    return Object.keys(stats.timestamp).map((key) => ({
      x: new Date(stats.timestamp[key]),
      learned: stats[`${type}_${category}_learned`][key],
      proficient: stats[`${type}_${category}_proficient`][key],
      mastered: stats[`${type}_${category}_mastered`][key],
    }));
  };

  

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const convertDataForLine = (data, key) => {
    return data.map(d => ({ x: d.x, y: d[key] }));
  };

  const getMaxLearned = (data) => {
    return Math.max(...data.map(d => d.learned));
  };

  const getNextThreshold = (maxLearned, thresholds) => {
    for (let i = 0; i < thresholds.length; i++) {
      if (maxLearned < thresholds[i].limit) {
        return thresholds[i].limit;
      }
    }
    return thresholds[thresholds.length - 1].limit;
  };

  const readingVocabData = prepareData(statistics.reading_stats, 'reading', 'vocab');
  const readingGrammarData = prepareData(statistics.reading_stats, 'reading', 'grammar');
  const listeningVocabData = prepareData(statistics.listening_stats, 'listening', 'vocab');
  const listeningGrammarData = prepareData(statistics.listening_stats, 'listening', 'grammar');
  const clozeVocabData = prepareData(statistics.cloze_stats, 'cloze', 'vocab');
  const clozeGrammarData = prepareData(statistics.cloze_stats, 'cloze', 'grammar');

  const maxReadingVocabLearned = getMaxLearned(readingVocabData);
  const maxReadingGrammarLearned = getMaxLearned(readingGrammarData);
  const maxListeningVocabLearned = getMaxLearned(listeningVocabData);
  const maxListeningGrammarLearned = getMaxLearned(listeningGrammarData);
  const maxClozeVocabLearned = getMaxLearned(clozeVocabData);
  const maxClozeGrammarLearned = getMaxLearned(clozeGrammarData);

  const maxReadingVocabLimit = getNextThreshold(maxReadingVocabLearned, vocabularyThresholds);
  const maxReadingGrammarLimit = getNextThreshold(maxReadingGrammarLearned, grammarThresholds);
  const maxListeningVocabLimit = getNextThreshold(maxListeningVocabLearned, vocabularyThresholds);
  const maxListeningGrammarLimit = getNextThreshold(maxListeningGrammarLearned, grammarThresholds);
  const maxClozeVocabLimit = getNextThreshold(maxClozeVocabLearned, vocabularyThresholds);
  const maxClozeGrammarLimit = getNextThreshold(maxClozeGrammarLearned, grammarThresholds);

  // Combine 'proficient' values from vocab and grammar for reading, listening, and cloze
  const combineLearnedData = (vocabData, grammarData) => {
    return vocabData.map((vocabEntry, index) => {
      const grammarEntry = grammarData[index]; // Assuming both arrays are aligned by date
      return {
        x: vocabEntry.x,
        learned: vocabEntry.learned + grammarEntry.learned,
      };
    });
  };

  //These lines are for retrieving the proficiency data and finding the limit for the cobined plot
  const readingCombinedLearnedData = combineLearnedData(readingVocabData, readingGrammarData);
  const listeningCombinedLearnedData = combineLearnedData(listeningVocabData, listeningGrammarData);
  const clozeCombinedLearnedData = combineLearnedData(clozeVocabData, clozeGrammarData);
  const getMaxProficient = (data) => {
    return Math.max(...data.map(d => d.proficient));
  };
  const maxReadingProficiency = getMaxProficient(readingCombinedLearnedData);
  const maxListeningProficiency = getMaxProficient(listeningCombinedLearnedData);
  const maxClozeProficiency = getMaxProficient(clozeCombinedLearnedData);
  const maxCombinedLearned = Math.max(maxReadingProficiency, maxListeningProficiency, maxClozeProficiency);
  const maxCombinedLearnedLimit = getNextThreshold(maxCombinedLearned, combinedThresholds);



  const getMinDate = (...datasets) => {
    return new Date(Math.min(...datasets.flat().map(d => d.x)));
  };
  const getMaxDate = (...datasets) => {
    return new Date(Math.max(...datasets.flat().map(d => d.x)));
  };
  // Combine min/max date across all three datasets
  const minDate = getMinDate(readingCombinedLearnedData, listeningCombinedLearnedData, clozeCombinedLearnedData);
  const maxDate = getMaxDate(readingCombinedLearnedData, listeningCombinedLearnedData, clozeCombinedLearnedData);


  return (
    <div>
      <h1>User Statistics</h1>

      <div>
        <label>
          <input
            type="radio"
            name="period"
            value="daily"
            checked={selectedPeriod === 'daily'}
            onChange={handlePeriodChange}
          />
          Daily
        </label>
        <label>
          <input
            type="radio"
            name="period"
            value="weekly"
            checked={selectedPeriod === 'weekly'}
            onChange={handlePeriodChange}
          />
          Weekly
        </label>
        <label>
          <input
            type="radio"
            name="period"
            value="monthly"
            checked={selectedPeriod === 'monthly'}
            onChange={handlePeriodChange}
          />
          Monthly
        </label>
        <label>
          <input
            type="radio"
            name="period"
            value="allTime"
            checked={selectedPeriod === 'allTime'}
            onChange={handlePeriodChange}
          />
          All Time
        </label>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-4 py-2 text-left">Category</th>
              <th className="px-4 py-2 text-left">Learned</th>
              <th className="px-4 py-2 text-left">Proficient</th>
              <th className="px-4 py-2 text-left">Mastered</th>
            </tr>
          </thead>
          <tbody>
            {['reading', 'listening', 'cloze'].map((category) => (
              <tr key={category} className="border-b hover:bg-gray-100">
                <td className="px-4 py-2">{category.charAt(0).toUpperCase() + category.slice(1)}</td>
                <td className="px-4 py-2">{learningSummary?.[category]?.learned || 0}</td>
                <td className="px-4 py-2">{learningSummary?.[category]?.proficient || 0}</td>
                <td className="px-4 py-2">{learningSummary?.[category]?.mastered || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
        {/* Chart for combined proficient data */}
        <div style={{ border: '1px solid black', padding: '15px' }}>
          <VictoryChart
            theme={VictoryTheme.material}
            minDomain={{ y: 0 }}
            maxDomain={{ y: (maxCombinedLearnedLimit && maxCombinedLearnedLimit > 0) ? maxCombinedLearnedLimit * 1.1 : 100 }} // Fallback max limit
            scale={{ x: 'time' }}
            style={{ parent: { maxWidth: '45vw', height: '35vh' } }}
            padding={{ top: 50, bottom: 60, left: 80, right: 50 }}
          >
            <VictoryLabel text="Overall Stats" style={{ fontSize: 24 }} x={225} y={30} textAnchor="middle" />
            <VictoryAxis
              label="Date"
              tickFormat={(x) => new Date(x).toLocaleDateString()}
              style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 0, textAnchor: 'middle' },
                tickLabels: { angle: 20, fontSize: 10, padding: 5, textAnchor: 'start' }
              }}
              tickCount={4}
            />
            <VictoryAxis dependentAxis
              label="Number of Learned Vocab + Grammar"
              style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 270, textAnchor: 'middle' }
              }}
            />
            <VictoryLegend
              orientation="vertical"
              x={400}
              y={50}
              gutter={20}
              data={[
                { name: 'Reading', symbol: { fill: 'green' } },
                { name: 'Listening', symbol: { fill: 'blue' } },
                { name: 'Cloze', symbol: { fill: 'red' } },
              ]}
            />


            
            {/* Combined Threshold Lines */}
            {combinedThresholds.map(threshold => (
              <VictoryLine
                key={threshold.label}
                style={{ data: { stroke: threshold.color, strokeDasharray: '4,4' } }}
                data={[
                  { x: minDate, y: threshold.limit },
                  { x: maxDate, y: threshold.limit }
                ]}
                labels={[threshold.label]}
                labelComponent={<VictoryLabel textAnchor="start" dx={5} />}
              />
            ))}
            
            {/* Check if data exists before rendering lines */}
            {readingCombinedLearnedData?.length > 0 && (
              <VictoryLine
                style={{ data: { stroke: 'green' } }}
                data={convertDataForLine(readingCombinedLearnedData, 'learned')}
              />
            )}
            
            {listeningCombinedLearnedData?.length > 0 && (
              <VictoryLine
                style={{ data: { stroke: 'blue' } }}
                data={convertDataForLine(listeningCombinedLearnedData,'learned')}
              />
            )}
            
            {clozeCombinedLearnedData?.length > 0 && (
              <VictoryLine
                style={{ data: { stroke: 'red' } }}
                data={convertDataForLine(clozeCombinedLearnedData,'learned')}
              />
            )}
            
          </VictoryChart>
        </div>

        
        <div style={{ border: '1px solid black', padding: '15px' }}>
        <VictoryChart
            theme={VictoryTheme.material}
            minDomain={{ y: 0 }}
            maxDomain={{ y: maxReadingVocabLimit * 1.1 }}
            scale={{ x: 'time' }}
            style={{ parent: { maxWidth: '45vw', height: '35vh' } }} // Increased maxWidth and height
            padding={{ top: 50, bottom: 60, left: 80, right: 50 }} // Increased padding
        >
            <VictoryLabel text="Reading Vocabulary Statistics" style={{ fontSize: 24 }} x={225} y={30} textAnchor="middle"/>
            <VictoryAxis dependentAxis
                label="Number of Vocab"
                style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 270, textAnchor: 'middle' }
                }}
            />
            <VictoryAxis
            label="Date"
            tickFormat={(x) => new Date(x).toLocaleDateString()}
            style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 0, textAnchor: 'middle' },
                tickLabels: { angle: 20, fontSize: 10, padding: 5, textAnchor: 'start' }
            }}
            tickCount={4} // Adjust this number as needed
            />
            <VictoryLegend
                orientation="vertical"
                x={400}  // Adjust this value based on your chart's width
                y={50}
                gutter={20}
                data={[
                { name: 'Learned', symbol: { fill: 'blue' } },
                { name: 'Proficient', symbol: { fill: 'green' } },
                { name: 'Mastered', symbol: { fill: 'red' } },
                ]}
            />
            {vocabularyThresholds.map(threshold => (
            <VictoryLine
              key={threshold.label}
              style={{ data: { stroke: threshold.color, strokeDasharray: '4,4' } }}
              data={[{ x: readingVocabData[0]?.x, y: threshold.limit }, { x: readingVocabData[readingVocabData.length - 1]?.x, y: threshold.limit }]}
              labels={[threshold.label]}
              labelComponent={<VictoryLabel textAnchor="start" dx={5} />}
              />
          ))}
            <VictoryLine
                style={{ data: { stroke: 'blue' } }}
                data={convertDataForLine(readingVocabData, 'learned')}
            />
            <VictoryLine
                style={{ data: { stroke: 'green' } }}
                data={convertDataForLine(readingVocabData, 'proficient')}
            />
            <VictoryLine
                style={{ data: { stroke: 'red' } }}
                data={convertDataForLine(readingVocabData, 'mastered')}
            />
            </VictoryChart>
        </div>

        <div style={{ border: '1px solid black', padding: '15px' }}>
        <VictoryChart
            theme={VictoryTheme.material}
            minDomain={{ y: 0 }}
            maxDomain={{ y: maxReadingGrammarLimit * 1.1 }}
            scale={{ x: 'time' }}
            style={{ parent: { maxWidth: '45vw', height: '35vh' } }} // Increased maxWidth and height
            padding={{ top: 50, bottom: 60, left: 80, right: 50 }} // Increased padding
        >
            <VictoryLabel text="Reading Grammar Statistics" style={{ fontSize: 24 }} x={225} y={30} textAnchor="middle"/>
            <VictoryAxis dependentAxis
                label="Number of Grammar"
                style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 270, textAnchor: 'middle' }
                }}
            />
            <VictoryAxis
            label="Date"
            tickFormat={(x) => new Date(x).toLocaleDateString()}
            style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 0, textAnchor: 'middle' },
                tickLabels: { angle: 20, fontSize: 10, padding: 5, textAnchor: 'start' }
            }}
            tickCount={4} // Adjust this number as needed
            />
            <VictoryLegend
                orientation="vertical"
                x={400}  // Adjust this value based on your chart's width
                y={50}
                gutter={20}
                data={[
                { name: 'Learned', symbol: { fill: 'blue' } },
                { name: 'Proficient', symbol: { fill: 'green' } },
                { name: 'Mastered', symbol: { fill: 'red' } },
                ]}
            />
            {grammarThresholds.map(threshold => (
            <VictoryLine
              key={threshold.label}
              style={{ data: { stroke: threshold.color, strokeDasharray: '4,4' } }}
              data={[{ x: readingGrammarData[0]?.x, y: threshold.limit }, { x: readingGrammarData[readingGrammarData.length - 1]?.x, y: threshold.limit }]}
              labels={[threshold.label]}
              labelComponent={<VictoryLabel textAnchor="start" dx={5} />}
            />
          ))}
            <VictoryLine
                style={{ data: { stroke: 'blue' } }}
                data={convertDataForLine(readingGrammarData, 'learned')}
            />
            <VictoryLine
                style={{ data: { stroke: 'green' } }}
                data={convertDataForLine(readingGrammarData, 'proficient')}
            />
            <VictoryLine
                style={{ data: { stroke: 'red' } }}
                data={convertDataForLine(readingGrammarData, 'mastered')}
            />
            </VictoryChart>
        </div>

        <div style={{ border: '1px solid black', padding: '15px' }}>
        <VictoryChart
            theme={VictoryTheme.material}
            minDomain={{ y: 0 }}
            maxDomain={{ y: maxListeningVocabLimit * 1.1 }}
            scale={{ x: 'time' }}
            style={{ parent: { maxWidth: '45vw', height: '35vh' } }} // Increased maxWidth and height
            padding={{ top: 50, bottom: 60, left: 80, right: 50 }} // Increased padding
        >
            <VictoryLabel text="Listening Vocabulary Statistics" style={{ fontSize: 24 }} x={225} y={30} textAnchor="middle"/>
            <VictoryAxis dependentAxis
                label="Number of Vocab"
                style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 270, textAnchor: 'middle' }
                }}
            />
            <VictoryAxis
            label="Date"
            tickFormat={(x) => new Date(x).toLocaleDateString()}
            style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 0, textAnchor: 'middle' },
                tickLabels: { angle: 20, fontSize: 10, padding: 5, textAnchor: 'start' }
            }}
            tickCount={4} // Adjust this number as needed
            />
            <VictoryLegend
                orientation="vertical"
                x={400}  // Adjust this value based on your chart's width
                y={50}
                gutter={20}
                data={[
                { name: 'Learned', symbol: { fill: 'blue' } },
                { name: 'Proficient', symbol: { fill: 'green' } },
                { name: 'Mastered', symbol: { fill: 'red' } },
                ]}
            />
            {vocabularyThresholds.map(threshold => (
            <VictoryLine
              key={threshold.label}
              style={{ data: { stroke: threshold.color, strokeDasharray: '4,4' } }}
              data={[{ x: listeningVocabData[0]?.x, y: threshold.limit }, { x: listeningVocabData[listeningVocabData.length - 1]?.x, y: threshold.limit }]}
              labels={[threshold.label]}
              labelComponent={<VictoryLabel textAnchor="start" dx={5} />}
              />
          ))}
            <VictoryLine
                style={{ data: { stroke: 'blue' } }}
                data={convertDataForLine(listeningVocabData, 'learned')}
            />
            <VictoryLine
                style={{ data: { stroke: 'green' } }}
                data={convertDataForLine(listeningVocabData, 'proficient')}
            />
            <VictoryLine
                style={{ data: { stroke: 'red' } }}
                data={convertDataForLine(listeningVocabData, 'mastered')}
            />
            </VictoryChart>
        </div>

        <div style={{ border: '1px solid black', padding: '15px' }}>
        <VictoryChart
            theme={VictoryTheme.material}
            minDomain={{ y: 0 }}
            maxDomain={{ y: maxListeningGrammarLimit * 1.1 }}
            scale={{ x: 'time' }}
            style={{ parent: { maxWidth: '45vw', height: '35vh' } }} // Increased maxWidth and height
            padding={{ top: 50, bottom: 60, left: 80, right: 50 }} // Increased padding
        >
            <VictoryLabel text="Listening Grammar Statistics" style={{ fontSize: 24 }} x={225} y={30} textAnchor="middle"/>
            <VictoryAxis dependentAxis
                label="Number of Grammar"
                style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 270, textAnchor: 'middle' }
                }}
            />
            <VictoryAxis
            label="Date"
            tickFormat={(x) => new Date(x).toLocaleDateString()}
            style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 0, textAnchor: 'middle' },
                tickLabels: { angle: 20, fontSize: 10, padding: 5, textAnchor: 'start' }
            }}
            tickCount={4} // Adjust this number as needed
            />
            
            <VictoryLegend
                orientation="vertical"
                x={400}  // Adjust this value based on your chart's width
                y={50}
                gutter={20}
                data={[
                { name: 'Learned', symbol: { fill: 'blue' } },
                { name: 'Proficient', symbol: { fill: 'green' } },
                { name: 'Mastered', symbol: { fill: 'red' } },
                ]}
            />
            {grammarThresholds.map(threshold => (
            <VictoryLine
              key={threshold.label}
              style={{ data: { stroke: threshold.color, strokeDasharray: '4,4' } }}
              data={[{ x: listeningGrammarData[0]?.x, y: threshold.limit }, { x: listeningGrammarData[listeningGrammarData.length - 1]?.x, y: threshold.limit }]}
              labels={[threshold.label]}
              labelComponent={<VictoryLabel textAnchor="start" dx={5} />}
            />
          ))}
            <VictoryLine
                style={{ data: { stroke: 'blue' } }}
                data={convertDataForLine(listeningGrammarData, 'learned')}
            />
            <VictoryLine
                style={{ data: { stroke: 'green' } }}
                data={convertDataForLine(listeningGrammarData, 'proficient')}
            />
            <VictoryLine
                style={{ data: { stroke: 'red' } }}
                data={convertDataForLine(listeningGrammarData, 'mastered')}
            />
            </VictoryChart>
        </div>

        <div style={{ border: '1px solid black', padding: '15px' }}>
          <VictoryChart
            theme={VictoryTheme.material}
            minDomain={{ y: 0 }}
            maxDomain={{ y: maxClozeVocabLimit * 1.1 }}
            scale={{ x: 'time' }}
            style={{ parent: { maxWidth: '45vw', height: '35vh' } }}
            padding={{ top: 50, bottom: 60, left: 80, right: 50 }}
          >
            <VictoryLabel text="Cloze Vocabulary Statistics" style={{ fontSize: 24 }} x={225} y={30} textAnchor="middle" />
            <VictoryAxis dependentAxis
              label="Number of Vocab"
              style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 270, textAnchor: 'middle' }
              }}
            />
            <VictoryAxis
              label="Date"
              tickFormat={(x) => new Date(x).toLocaleDateString()}
              style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 0, textAnchor: 'middle' },
                tickLabels: { angle: 20, fontSize: 10, padding: 5, textAnchor: 'start' }
              }}
              tickCount={4}
            />
            <VictoryLegend
              orientation="vertical"
              x={400}
              y={50}
              gutter={20}
              data={[
                { name: 'Learned', symbol: { fill: 'blue' } },
                { name: 'Proficient', symbol: { fill: 'green' } },
                { name: 'Mastered', symbol: { fill: 'red' } },
              ]}
            />
            {vocabularyThresholds.map(threshold => (
              <VictoryLine
                key={threshold.label}
                style={{ data: { stroke: threshold.color, strokeDasharray: '4,4' } }}
                data={[{ x: clozeVocabData[0]?.x, y: threshold.limit }, { x: clozeVocabData[clozeVocabData.length - 1]?.x, y: threshold.limit }]}
                labels={[threshold.label]}
                labelComponent={<VictoryLabel textAnchor="start" dx={5} />}
              />
            ))}
            <VictoryLine
              style={{ data: { stroke: 'blue' } }}
              data={convertDataForLine(clozeVocabData, 'learned')}
            />
            <VictoryLine
              style={{ data: { stroke: 'green' } }}
              data={convertDataForLine(clozeVocabData, 'proficient')}
            />
            <VictoryLine
              style={{ data: { stroke: 'red' } }}
              data={convertDataForLine(clozeVocabData, 'mastered')}
            />
          </VictoryChart>
        </div>

        <div style={{ border: '1px solid black', padding: '15px' }}>
          <VictoryChart
            theme={VictoryTheme.material}
            minDomain={{ y: 0 }}
            maxDomain={{ y: maxClozeGrammarLimit * 1.1 }}
            scale={{ x: 'time' }}
            style={{ parent: { maxWidth: '45vw', height: '35vh' } }}
            padding={{ top: 50, bottom: 60, left: 80, right: 50 }}
          >
            <VictoryLabel text="Cloze Grammar Statistics" style={{ fontSize: 24 }} x={225} y={30} textAnchor="middle" />
            <VictoryAxis dependentAxis
              label="Number of Grammar"
              style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 270, textAnchor: 'middle' }
              }}
            />
            <VictoryAxis
              label="Date"
              tickFormat={(x) => new Date(x).toLocaleDateString()}
              style={{
                axisLabel: { padding: 35, fontSize: 18, angle: 0, textAnchor: 'middle' },
                tickLabels: { angle: 20, fontSize: 10, padding: 5, textAnchor: 'start' }
              }}
              tickCount={4}
            />
            <VictoryLegend
              orientation="vertical"
              x={400}
              y={50}
              gutter={20}
              data={[
                { name: 'Learned', symbol: { fill: 'blue' } },
                { name: 'Proficient', symbol: { fill: 'green' } },
                { name: 'Mastered', symbol: { fill: 'red' } },
              ]}
            />
            {grammarThresholds.map(threshold => (
              <VictoryLine
                key={threshold.label}
                style={{ data: { stroke: threshold.color, strokeDasharray: '4,4' } }}
                data={[{ x: clozeGrammarData[0]?.x, y: threshold.limit }, { x: clozeGrammarData[clozeGrammarData.length - 1]?.x, y: threshold.limit }]}
                labels={[threshold.label]}
                labelComponent={<VictoryLabel textAnchor="start" dx={5} />}
              />
            ))}
            <VictoryLine
              style={{ data: { stroke: 'blue' } }}
              data={convertDataForLine(clozeGrammarData, 'learned')}
            />
            <VictoryLine
              style={{ data: { stroke: 'green' } }}
              data={convertDataForLine(clozeGrammarData, 'proficient')}
            />
            <VictoryLine
              style={{ data: { stroke: 'red' } }}
              data={convertDataForLine(clozeGrammarData, 'mastered')}
            />
          </VictoryChart>
        </div>
    </div>
  );
}

export default Statistics;