import React, { useState } from 'react';

function CollapsibleContainer({ title, children, onToggle }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (onToggle) {
      onToggle(!isOpen);
    }
  };

  return (
    <div className="collapsible-container" style={{ marginBottom: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
      <div 
        onClick={handleToggle} 
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '10px' }}
      >
        <span style={{ marginRight: '10px' }}>{isOpen ? '-' : '+'}</span>
        <h2 style={{ margin: 0 }}>{title}</h2>
      </div>
      {isOpen && <div style={{ padding: '10px' }}>{children}</div>}
    </div>
  );
}

export default CollapsibleContainer;